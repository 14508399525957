import React, { useContext } from 'react';
import { GlobalContext } from '../globalState';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CardList from '../conpornent/CardList';

function ReserveListPage(props) {

  const envReserveUrl = useContext(GlobalContext).envReserveUrl;

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <CardList
        url={envReserveUrl.MCRVURL}
        title={envReserveUrl.MCRVTITLE}
        subTitle={envReserveUrl.MCRVSUBTITLE}
        discription={envReserveUrl.MCRVDISCRIPTION}
        img={envReserveUrl.MCRVIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envReserveUrl.MOCRVURL}
        title={envReserveUrl.MOCRVTITLE}
        subTitle={envReserveUrl.MOCRVSUBTITLE}
        discription={envReserveUrl.MOCRVDISCRIPTION}
        img={envReserveUrl.MOCRVIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envReserveUrl.MCTTRVURL}
        title={envReserveUrl.MCTTRVTITLE}
        subTitle={envReserveUrl.MCTTRVSUBTITLE}
        discription={envReserveUrl.MCTTRVDISCRIPTION}
        img={envReserveUrl.MCTTRVIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envReserveUrl.HELLORVURL}
        title={envReserveUrl.HELLORVTITLE}
        subTitle={envReserveUrl.HELLORVSUBTITLE}
        discription={envReserveUrl.HELLORVDISCRIPTION}
        img={envReserveUrl.HELLORVIMG}
      />
    </List>
  );
}

export default ReserveListPage;
