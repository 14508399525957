import React, { useRef, useState, useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import QrReader from 'react-qr-scanner';
import axios from 'axios';
import * as commonFnc from '../bl/BL_common';

const SCAN_DELAY_TIME = 1000;

const isValidQRFormat = (str) => {
  // 正規表現パターン: Mの後に5桁の数字、ハイフン、9桁の数字
  const pattern = /^M\d{5}-\d{9}$/;
  return pattern.test(str);
};

const isSameLastId = (str, lastId) => {
  return str === lastId;
};

const postCallData = async (args) => {
  await axios.post('/tree/api/upsertCall', { args });
};

const deptgrHash = {
  M00133: '2',
  M00233: '1',
  M00333: '5',
  M00433: '7',
};

function QRCodeReaderPage() {
  const [data, setData] = useState('No result');
  const [location] = useState('Location2');
  const [display] = useState('Display2');


  const lastScanTime = useRef(Date.now());
  
  const handleScan = (result) => {

    const now = Date.now();
    if (now - lastScanTime.current < SCAN_DELAY_TIME) return;
    lastScanTime.current = now;

    console.log("scan");

    if (result) {

      const resultSplit = result.text.split('-');
      const atlinkCd = resultSplit[0];
      const patientId = resultSplit[1];

      // if (!isValidQRFormat(result.text)) return;
      if (isSameLastId(result.text, data)) return;

      const today = new Date();
      const callDate = commonFnc.formatDate(today);
      const callTime = commonFnc.formatTime(today);
      const deptgrCd = deptgrHash[atlinkCd];

      // DEPTGR_CD,LOCATION,DISPLAY,PATIENT_ID,CALL_DATE,CALL_TIME,DONE_FLG
      const args = [deptgrCd, location, display, patientId, callDate, callTime, false];
      postCallData(args);
      setData(result.text);
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const videoConstraints = {
    facingMode: { exact: 'environment' }, // リアカメラを指定
  };


  
  return (
    <div className="App">
      <h1>受診票読込</h1>
      <QrScanner  
        delay={1000} // 1秒間隔に設定
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
        constraints={{ video: videoConstraints }}
      />
      <p>{data}</p>
    </div>
  );
}

export default QRCodeReaderPage;
