import '../../css/top/mainMenuButton.css';

import Appbar from '../../conpornent/Appbar'
import NoticeList from '../../conpornent/NoticeList'


import Box from '@mui/material/Box';

function Miyake_LoadMap() {
  return (
    <Box>
        <h1 class="pageTitle">出産が決まったら？</h1>
        <section>
            <div class="contentWrapper">
                <h3 class="sectionTitle">妊娠約8～10週頃、ご出産の予定日が決まります</h3>
                <div class="content">
                    <p>新しい命を宿し、喜びと幸福感いっぱいのことと思います。
                        これからの妊娠経過や出産・育児などのご心配が湧くと思いますが、三宅医院スタッフ一同で心をこめて応援させていただきます。いつでもご相談ください。
                        心も体も健康!!を目標に、妊娠中の生活を元気に乗り切りましょう。</p>
                </div>
            </div>
        </section>
        <section>
            <div class="contentWrapper">
            <h3 class="sectionTitle">お知らせ</h3>
            <NoticeList/>
            </div>
        </section>
    </Box>
  );
}

export default Miyake_LoadMap;
