
import { useState } from 'react';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import axios from 'axios';
import { selectUserMail } from "../query/selectUserMail";


const formatDate = () => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため、1を加えています。2桁表示になるように0埋めしています。
    const day = String(today.getDate()).padStart(2, '0'); // 日付を2桁表示にするため、0埋めしています。

    return `${year}-${month}-${day}`;
}


const SingUp = (props) =>{

    const [pw, setPw] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [sex, setSex] = useState('');
    const [birthDay, setBirthDay] = useState(formatDate());
    const [mail, setMail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMail, setErrorMail] = useState(false);
    const [errorMailMsg, setErrorMailMsg] = useState('');

    /**
     * 初回登録用のメールを送信する
     * @param {*} firstName 
     * @param {*} lastName 
     * @param {*} sex 
     * @param {*} birthDay 
     * @param {*} pw 
     * @param {*} mail 
     */
    const handleSendMail = async (firstName, lastName, sex, birthDay, pw, mail) => {
        try {
            // バリデーションチェック
            const dataloginUserInfoList = await selectUserMail(mail);
            if(dataloginUserInfoList.length > 0){
                const msg = 'このメールアドレスは既に使用されています。';
                alert(msg);
                setErrorMail(true);
                setErrorMailMsg(msg);
            }else{
                // ユーザー登録リクエストを送信
                await axios.post('tree/api/sendMail', { firstName, lastName, sex, birthDay, pw, mail });
                alert('認証メールを送信しました。\nメールに記載の内容をご確認ください。')
            }
        } catch (error) {
            // エラーメッセージを設定
            if (error.response) {
                setErrorMessage(error.response.data);
            } else {
                setErrorMessage('サーバーエラーが発生しました。');
            }
        }
    }


    return (
        <div class="signIn_body">
            <h3>新規アカウント登録</h3>
            <div>
                <TextField className={"signin_field short"} type="text" label="氏" variant="standard" value={lastName} onChange={(e)=>{setLastName(e.target.value)}} placeholder='三宅' required/>
                <TextField className={"signin_field short"} type="text" label="名" variant="standard" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}} placeholder='花子' required/>
            </div>
            <div>
                {/* {!birthDay?<></>: */}
                <TextField
                    className={"signin_field"}
                    type="date"
                    label="生年月日"
                    variant="standard"
                    value={birthDay}
                    onChange={(e)=>{setBirthDay(e.target.value)}}
                />
                {/* } */}
            </div>
            <div>
                <FormControl sx={{ width: "100%" }}>
                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="1"
                    name="radio-buttons-group"
                    value={sex}
                    onChange={(e)=>setSex(e.target.value)}
                    row
                    sx={{ width: "90%" ,margin:'0 auto'}}
                    >
                    <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="男"
                        sx={{ margin:'0 20%'}}
                    />
                    <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="女"
                    />
                    </RadioGroup>
                </FormControl>
            </div>
            <div>
                <TextField 
                    className={"signin_field"} 
                    type="mail" 
                    label="mail" 
                    variant="standard" 
                    value={mail} 
                    onChange={(e)=>{setMail(e.target.value)}} 
                    placeholder='aaaa@exsample.com'
                    error={errorMail}
                    helperText={errorMailMsg}
                />
            </div>
            <div>
                <TextField
                    className={"signin_field"}
                    type="password"
                    label="PW"
                    variant="standard"
                    value={pw}
                    onChange={(e)=>{setPw(e.target.value)}}
                />
            </div>
            <div>
                <Button 
                    className={"signin_button"}
                    variant="contained" 
                    onClick={()=>handleSendMail(firstName, lastName, sex, birthDay, pw, mail)}
                >認証メールを送信</Button>
            </div>
            <div>
                <p>{errorMessage}</p>
            </div>
        </div>
    );
}

export default SingUp;