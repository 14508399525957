import { createContext, useState } from "react";

export const GlobalContext = createContext({});

export const GlobalProvider = (props) => {

  const [envWebMonshin, setEnvenvWebMonshin] = useState({
    "MCWEBMONSHINURL" :  'https://m00233.mi.atlink.jp/login',
    "MOCWEBMONSHINURL" :  'https://m00333.mi.atlink.jp/login',
    "MCTTWEBMONSHINURL" :  'https://m00133.mi.atlink.jp/login',
    "HELLOWEBMONSHINURL" : 'https://m00433.mi.atlink.jp/login',
    "MCTITLE" : '三宅医院',
    "MOCTITLE" : '三宅おおふくクリニック',
    "MCTTTITLE" : '三宅医院 問屋町テラス',
    "HELLOTITLE" : '三宅ハロー歯科',
    "MCSUBTITLE" : '三宅医院',
    "MOCSUBTITLE" : '三宅おおふくクリニック',
    "MCTTSUBTITLE" : '三宅医院 問屋町テラス',
    "HELLOSUBTITLE" : '三宅ハロー歯科',
    "MCDISCRIPTION" : '三宅医院のWEB問診に回答していただけます。',
    "MOCDISCRIPTION" : '三宅おおふくクリニックのWEB問診に回答していただけます。',
    "MCTTDISCRIPTION" : '三宅医院 問屋町テラスのWEB問診に回答していただけます。',
    "HELLODISCRIPTION" : '三宅ハロー歯科のWEB問診に回答していただけます。',
    "MCIMG" : "/img/department/mc.png",
    "MOCIMG" : "/img/department/moc.png",
    "MCTTIMG" : "/img/department/mctt.png",
    "HELLOIMG" : "/img/department/hello.png",
  });
  
  const [envDockReserve, setEnvDockReserve] = useState({
    "DOCKURL" : 'https://ofuku-miyakegroup.jp/dockform/',
    "KENSHIURL" : 'https://ofuku-miyakegroup.jp/dockkenshin/form/reserveform/',
    "OPTIONURL" : 'https://ofuku-miyakegroup.jp/dockkenshin/form/optionform/',
    "DOCKTITLE" : '人間ドックWEB予約',
    "KENSHINTITLE" : '健康診断WEB予約',
    "OPTIONTITLE" : 'オプション追加の申込み',
    "DOCKSUBTITLE" : '',
    "KENSHINSUBTITLE" : '',
    "OPTIONSUBTITLE" : '',
    "DOCKDISCRIPTION" : '人間ドックのお申込みはこちら',
    "KENSHINDISCRIPTION" : '健康診断のお申込みはこちら',
    "OPTIONDISCRIPTION" : 'オプション検査の追加のお申込みはこちら',
    "DOCKIMG" : "/img/dockkensin/dock.png",
    "KENSHINIMG" : "/img/dockkensin/kenshin.png",
    "OPTIONIMG" : "/img/dockkensin/option.png",
  });
  
  const [envReserveUrl, setEnvReserveUrl] = useState({
    "MCRVURL" :'https://a.atlink.jp/miyake/',
    "MOCRVURL" :'https://yoyaku.atlink.jp/miyakeofuku/',
    "MCTTRVURL" :'https://a.atlink.jp/toiyacho/',
    "HELLORVURL" : 'https://yoyaku.atlink.jp/miyakehello/',
    "MCRVTITLE" : '三宅医院',
    "MOCRVTITLE" : '三宅おおふくクリニック',
    "MCTTRVTITLE" : '三宅医院 問屋町テラス',
    "HELLORVTITLE" : '三宅ハロー歯科',
    "MCRVSUBTITLE" : '',
    "MOCRVSUBTITLE" : '',
    "MCTTRVSUBTITLE" : '',
    "HELLORVSUBTITLE" : '',
    "MCRVDISCRIPTION" : '外来診療のご予約はこちら',
    "MOCRVDISCRIPTION" : '外来診療のご予約はこちら',
    "MCTTRVDISCRIPTION" : '外来診療のご予約はこちら',
    "HELLORVDISCRIPTION" : '外来診療のご予約はこちら',
    "MCRVIMG" : "/img/department/mc.png",
    "MOCRVIMG" : "/img/department/moc.png",
    "MCTTRVIMG" : "/img/department/mctt.png",
    "HELLORVIMG" : "/img/department/hello.png",
  });
  
  const [envHP, setEnvHP] = useState({
    "MCURL" : 'https://miyake-miyakegroup.jp/',
    "MOCURL" : 'https://ofuku-miyakegroup.jp/',
    "MCTTURL" : 'https://miyake-miyakegroup.jp/toiyacho/',
    "HELLOURL" : 'https://hello-miyakegroup.jp/',
  });

  const [envAtlinkUrl, setEnvAtlinkUrl] = useState({
    "MCATLINKURL" :'https://a.atlink.jp/miyake/',
    "MOCATLINKURL" :'https://yoyaku.atlink.jp/miyakeofuku/',
    "MCTTATLINKURL" :'https://a.atlink.jp/toiyacho/',
    "HELLOATLINKURL" : 'https://yoyaku.atlink.jp/miyakehello/',
  });

  const [envShinryouUrl, setEnvShinryouUrl] = useState({
    "MCURL" :'https://miyake-miyakegroup.jp/resev/',
    "MOCURL" :'https://ofuku-miyakegroup.jp/shinryou/',
    "MCTTURL" :'https://miyake-miyakegroup.jp/toiyacho/resev/',
    "HELLOURL" : 'https://hello-miyakegroup.jp/info/',
  });
  
  const [envInfomationUrl, setEnvInfomationUrl] = useState({
    "MCURL" :'https://miyake-miyakegroup.jp/news/',
    "MOCURL" :'https://ofuku-miyakegroup.jp/news/',
    "MCTTURL" :'https://miyake-miyakegroup.jp/toiyacho/news/',
    "HELLOURL" : 'https://hello-miyakegroup.jp/news/',
  });

  const [loginUserInfo, setLoginUserInfo] = useState(null);

  const [deptgrList, setDeptgrList] = useState([]);

  const [iframeUrl, setIframeUrl] = useState('');

  const [selectedTreeInformationId, setSelectedTreeInformationId] = useState(null);

  return(
    <GlobalContext.Provider value={{
      envWebMonshin, setEnvenvWebMonshin,
      envDockReserve, setEnvDockReserve,
      envReserveUrl, setEnvReserveUrl,
      envHP, setEnvHP,
      envAtlinkUrl, setEnvAtlinkUrl,
      envShinryouUrl, setEnvShinryouUrl,
      envInfomationUrl, setEnvInfomationUrl,
      loginUserInfo, setLoginUserInfo,
      deptgrList, setDeptgrList,
      iframeUrl, setIframeUrl,
      selectedTreeInformationId, setSelectedTreeInformationId,
      }}>
      {props.children}
    </GlobalContext.Provider>
  )
}