import { useEffect, useState, useRef, useContext } from "react";
import { GlobalContext } from "../globalState";
import '../css/top/mainMenuButton.css';

function GlobalStateComponent(props) {
    const {deptgrList, setDeptgrList} = useContext(GlobalContext);

    useEffect(()=>{
        const fetchData = async () => {
            const res = await fetch('/tree/api/selectDeptgr');
            const data = await res.json();
            setDeptgrList(data);
        }
        fetchData();
    },[])

    return ;
}
export default GlobalStateComponent;