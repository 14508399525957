import React, { useRef } from 'react';
import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import * as commonFnc from '../bl/BL_common';
import axios from 'axios';

const style = {
  content:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    backgroundImage:'url("/img/infomationBackground.png")',
    
  },
  titleArea:{ 
    borderRadius:'4px',
    padding:'3%',
    backgroundColor:'snow',
    opacity:'0.9',
  },
  title:{ 
    textAlign: 'center' ,
    fontSize:'16px',
  },
  informationBody:{
    opacity:'0.9',
    backgroundColor:'snow',
  },
  buttonStyle:{
    width: '23%',
    margin: '15px 1%',
  },
  TextFieldStyle:{
    width:'100%',
  },
  buttonArea:{
    borderRadius:'4px',
    backgroundColor:'snow',
    opacity:'0.9',
  }
};


function TreeInformationModalComponent (props) {

  const {
    treeInformationModalOpen,
    setTreeInformationModalOpen,
    treeInformationIsModalList
  } = props
  
  const {loginUserInfo} = useContext(GlobalContext);  

  const [targetInfoIndex, setTargetInfoIndex] = useState(0);
  const [targetInformation, setTargetInformation] = useState({});
  
  const handleOpen = () => setTreeInformationModalOpen(true);
  const handleClose = () => setTreeInformationModalOpen(false);

  useEffect(()=>{
    // モーダル表示対象のお知らせが無ければクローズ
    if(treeInformationIsModalList.length===0){
      handleClose();
    }
  },[treeInformationModalOpen]);


  useEffect(()=>{
      if(targetInfoIndex>(treeInformationIsModalList.length-1) || targetInfoIndex<0){
        handleClose();
        setTreeInformationModalOpen(false);
      }else{
        setTargetInformation(treeInformationIsModalList[targetInfoIndex]);
      }
  },[targetInfoIndex, treeInformationIsModalList]);


  
  // 次へ
  const indexIncriment = () =>{
    setTargetInfoIndex(targetInfoIndex+1);
    restartAnimationRight();
  }

  // 前へ
  const indexDecriment = () =>{
    setTargetInfoIndex(targetInfoIndex-1);
    restartAnimationLeft();
  }

  // 既読
  const readInfomation = async () =>{
    
    if(!loginUserInfo.TREEID) return;

    const now = new Date();

    const readInfo = {
      treeId:loginUserInfo.TREEID,
      infoId:targetInformation.INFO_ID,
      readDate:commonFnc.formatDate(now),
      readTime:commonFnc.formatTime(now),
    }

    await axios.post('/tree/api/upsertTreeInformationAlreadyRead', { readInfo:readInfo });  
    indexIncriment();
  }


  // アニメーションを再度実行する関数
  const restartAnimationRight = () => {
    const modal = document.querySelector('.tree-information-modal');
    if (modal) {
      modal.classList.remove('animetion-right', 'animetion-left'); // アニメーションクラスを一旦削除
      void modal.offsetWidth; // アニメーションがリセットされるために一時的にリフローを発生させる
      modal.classList.add('animetion-right'); // アニメーションクラスを再度追加して再生
    }
  };
  // アニメーションを再度実行する関数
  const restartAnimationLeft = () => {
    const modal = document.querySelector('.tree-information-modal');
    if (modal) {
      modal.classList.remove('animetion-right', 'animetion-left'); // アニメーションクラスを一旦削除
      void modal.offsetWidth; // アニメーションがリセットされるために一時的にリフローを発生させる
      modal.classList.add('animetion-left'); // アニメーションクラスを再度追加して再生
    }
  };

  return (
      <Modal
        open={treeInformationModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"tree-information-modal animetion-right"}
      >
        <Box sx={style.content}>
          <Box sx={style.titleArea}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={style.title}>
              {targetInformation.TITLE}
            </Typography>
          </Box>
          <Box sx={style.informationBody}>
            <TextField
              multiline
              rows={16}
              sx={style.TextFieldStyle}
              value={targetInformation.NOTE}
            >
            </TextField>
          </Box>
          <Box sx={style.buttonArea}>
            <Button sx={style.buttonStyle} onClick={handleClose}>閉じる</Button>
            <Button sx={style.buttonStyle} onClick={indexDecriment} disabled={targetInfoIndex<=0}>＜</Button>
            <Button sx={style.buttonStyle} onClick={indexIncriment} disabled={targetInfoIndex>=(treeInformationIsModalList.length-1)}>＞</Button>
            <Button sx={style.buttonStyle} onClick={readInfomation} disabled={!loginUserInfo}>既読</Button>
          </Box>
        </Box>
      </Modal>
  );
};

export default TreeInformationModalComponent;