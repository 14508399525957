
const maintenanceStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center'
};

function MaintenancePage(props) {

  return (
    <div style={maintenanceStyle}>
      <h3>現在このページは<br/>メンテナンス中です。</h3>
    </div>
  );
}

export default MaintenancePage;
