
import { useState, useContext } from 'react';
import { Button, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../globalState";
import { selectUserMailPw } from "../query/selectUserMailPw";
import * as BL_LoadLocalStrage from '../bl/BL_LoadLocalStrage';


const SingIn = (props) =>{

    const [mail, setMail] = useState('');
    const [pw, setPw] = useState('');

    const navigate = useNavigate();
    const setLoginUserInfo = useContext(GlobalContext).setLoginUserInfo;

    /**
     * ログインする。
     * @param {*} inputMail
     * @param {*} inputPw 
     */
    const login = async (inputMail, inputPw) => {
        // バリデーションチェック
        const dataloginUserInfoList = await selectUserMailPw(inputMail, inputPw);

        if(dataloginUserInfoList.length > 0){
            BL_LoadLocalStrage.login(dataloginUserInfoList[0]);
            setLoginUserInfo(dataloginUserInfoList[0]);
            navigate('/');
        }else{
            alert('IDかPWが間違っています。');
        }

    }

    return (
        <div class="signIn_body">
            <div>
                <TextField 
                    className={"signin_field"} 
                    type="text" 
                    label="Mail" 
                    variant="standard" 
                    value={mail} 
                    onChange={(e)=>{setMail(e.target.value)}}
                />
            </div>
            <div>
                <TextField
                    className={"signin_field"}
                    type="password"
                    label="PassWord"
                    variant="standard"
                    value={pw}
                    onChange={(e)=>{setPw(e.target.value)}}
                />
            </div>
            <div>
                <Button 
                    className={"signin_button"} 
                    variant="contained" 
                    onClick={()=>login(mail, pw)}
                >ログイン</Button>
                <p>登録がまだの方は<a href='/SignUp'>こちら</a></p>
            </div>
        </div>
    );
}

export default SingIn;