import '../css/signin/signIn.css'
import SingUp from "../conpornent/SignUp";
const SignUpPage = (props) =>{
    
    return (
        <div>
            <div class="signIn_header"/>
            <div class="signin_wrapper">
                <div class="signin_content">
                    <SingUp/>
                </div>
            </div>
        </div>
    );
}

export default SignUpPage;