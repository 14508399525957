import { useNavigate } from "react-router-dom";

export function handleOnChangeObj(e, Obj, setObj){
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setObj({
    ...Obj,
    [name]:value
    })

}

export function handleOnChangeStr(e, setObj){
    const target = e.target;
    const value = target.value;
    setObj(value)
}


export function CheckSingIn(loginUserInfo){

    // const navigate = useNavigate();

    if(!loginUserInfo||!loginUserInfo.ID){
        alert('ログイン情報がありません。')
        // navigate.push('/');
        window.location.href = "/";
    }
    
}

/**
 * 0削除　先頭の0を削除
 * @param {} str 
 * @returns 
 */
export function removeLeadingZeros(str) {
    // 文字列のすべてがゼロの場合はゼロを返す
    if (/^0+$/.test(str)) {
        return '0';
    }

    // 先頭のゼロを削除する正規表現
    return str.replace(/^0+/, '');
}

/**
 * 10桁0埋め　先頭の0を削除
 * @param {*} number 
 * @returns 
 */
export function zeroPadToTenDigits(number) {
    // 文字列に変換
    let str = String(number);

    // 文字列の長さが10になるまで先頭に'0'を追加
    while (str.length < 10) {
        str = '0' + str;
    }

    return str;
}


/**
 * 日付から曜日を取得する関数
 * @param {string} dateString - 日付を表す文字列（YYYY-MM-DD）
 * @returns {string} 曜日を表す文字列（日、月、火、水、木、金、土）
 */
export function getDayOfWeek(dateString) {

    const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    const dayOfWeek = daysOfWeek[dayOfWeekIndex];
    
    return dayOfWeek;
  }


/**
 * Date型の日付をString yyyy-mm-dd形式に変換する
 * @param {Date} targetDate 
 * @returns {String} yyyy-mm-dd
 */
export function formatDate(targetDate){

    return new Date(targetDate).toISOString().split("T")[0]

}

/**
 * Date型の日付をString hh:mm:ss形式に変換する
 * @param {Date} targetDate 
 * @returns {String} hh:mm:ss
 */
export function formatTime(targetDate) {
    const date = new Date(targetDate);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

/**
 * 指定された日時を "yyyy/MM/dd hh:mm:ss" 形式の文字列にフォーマットします。
 * 
 * @param {Date} targetDate - フォーマットする日時。
 * @returns {string} フォーマットされた日時文字列。
 */
export function formatDateTime(targetDate) {
    const year = targetDate.getFullYear(); // 年
    const month = String(targetDate.getMonth() + 1).padStart(2, '0'); // 月（0から始まるため+1）
    const day = String(targetDate.getDate()).padStart(2, '0'); // 日
    const hours = String(targetDate.getHours()).padStart(2, '0'); // 時
    const minutes = String(targetDate.getMinutes()).padStart(2, '0'); // 分
    const seconds = String(targetDate.getSeconds()).padStart(2, '0'); // 秒
  
    // yyyy/MM/dd hh:mm:ss 形式で結果を返す
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  }

/**
 * 指定した日における年齢を計算します。
 * 
 * @param {string} birthDate - 生年月日（YYYY-MM-DD形式）
 * @param {string} specificDate - 年齢を計算したい特定の日付（YYYY-MM-DD形式）
 * @returns {number} 指定した日における年齢
 */
export function calculateAge(birthDate, specificDate) {
    // 生年月日と特定の日付をDateオブジェクトに変換
    var birth = new Date(birthDate);
    var date = new Date(specificDate);

    // 年齢を計算
    var age = date.getFullYear() - birth.getFullYear();

    // まだ誕生日を迎えていない場合は1を引く
    var m = date.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && date.getDate() < birth.getDate())) {
        age--;
    }

    return age;
}

/**
 * fetchでデータを取得する
 * @param {*} url 
 * @returns 
 */
export async function fetchData(url) {

    try{
        const res = await fetch(`${url}`);
        if(!res.ok){
            throw new Error('Network response was not ok');
        }
        const data = await res.json();
        return data
    }catch (error){
        console.error('fetch errer : ', error.message);
    }

}