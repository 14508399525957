import * as React from 'react';
import Button from '@mui/material/Button';
import '../css/viewIFrame/viewIFrame.css';

function SummaryButton(props) {
    const {
        seletedHp,
        setSelectedHp,
        setIframeUrl,
        label,
        clinicStr,
        url} = props;
    
    const handleHpLinkButtonClick = (clinicStr, url) => {
        setSelectedHp(clinicStr);
        setIframeUrl(url);
    }

    return (
        <Button variant="contained" className={seletedHp===clinicStr?'clinicButton selectedButton':'clinicButton'} onClick={()=>handleHpLinkButtonClick(clinicStr, url)}>
          {label}
        </Button>
    );
}

export default SummaryButton;
