import * as React from 'react';

import '../css/viewIFrame/viewIFrame.css';

function ViewIFrame(props) {
    const {iframeUrl} = props;
    
    return (
        <div className='iframeWrapper'>
            {iframeUrl?
            <iframe
            title="Website"
            src={iframeUrl}
            width="100%"
            height="100%"
            allowFullScreen
            style={{ overflow: 'hidden' }}  // スクロールバーを非表示
            />:null}
        </div>
    );
}

export default ViewIFrame;
