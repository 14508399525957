import '../css/viewIFrame/viewIFrame.css';

import * as React from 'react';

import Typography from '@mui/material/Typography';
import { Button, Card, CardActions, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const style = {padding:'5px 10px'}

/**
 * DishesCard
 * @param {*} title 
 * @param {*} subTitle 
 * @param {*} discription 
 * @param {*} img 
 * @returns 
 */
function DishesCard(props) {
    const {
        title,
        subTitle,
        discription,
        img,
        dishId,
    } = props;

    const navigate = useNavigate();

    const handleOnClickMoreButton =()=>{
        navigate(`gallery/${dishId}`);
    }

    const handleShareButtonClick = async () => {

      const currentDomain = window.location.hostname;
      console.log(currentDomain); 

      try {
        await navigator.share({
          title: title,
          text: title,
          url: `https://${currentDomain}/galleryDishes/gallery/${dishId}`,
        });
        console.log('共有成功');
      } catch (error) {
        console.error('共有失敗:', error);
      }
    };

    return (
        <Card sx={{ maxWidth: '90%' ,margin:'5%'}}>
          <CardMedia
            sx={{ height: 140 }}
            image={img}
            title={title}
          />
          <CardContent sx={style}>
            <Typography gutterBottom variant="h6" component="div">
              {subTitle}
            </Typography>
            {/* <Typography variant="body2" color="text.secondary">
              {discription}
            </Typography> */}
          </CardContent>
          <CardActions sx={style}>
            <Button size="small" onClick={handleShareButtonClick}>Share</Button>
            <Button size="small" onClick={handleOnClickMoreButton}>Learn More →</Button>
          </CardActions>
        </Card>
    );
}

export default DishesCard;
