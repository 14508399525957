import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';
import { Box, Divider, List, ListItemButton, ListItemText, ListSubheader } from "@mui/material";
import * as commonFnc from '../bl/BL_common';
import { useNavigate } from "react-router-dom";
import { selectTreeInformationIsViewList } from "../query/selectTreeInformationIsViewList";
import '../css/treeInformation/treeInformation.css'


const style = {
  BoxStyle:{
    Width:'100%',
    padding:'5%',
  },
  ListSubheader:{
    display:'flex',
  },
  ListSubheaderText:{
    width:'85%',
  }
}


/**
 * TREEお知らせ情報一覧画面。
 * @param {*} props
 * @returns 
 */
function TreeInformationListPage(props) {
  const {loginUserInfo} = useContext(GlobalContext);  

  const [treeInformationList, setTreeInformationList] = useState([]);
  const {selectedTreeInformationId, setSelectedTreeInformationId} = useContext(GlobalContext);

  const navigate = useNavigate();

  
  useEffect(()=>{
    const fetchTreeInformationList = async ()=>{

      const infoList = await selectTreeInformationIsViewList(loginUserInfo?.TREEID);
      setTreeInformationList(infoList);
    }
    fetchTreeInformationList();
    
  },[])
  

  const handleInfoClick = (infoId) =>{
    setSelectedTreeInformationId(infoId);
    navigate('/treeInformationForm');
  }

  return (
      <Box sx={style.BoxStyle}>
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" sx={style.ListSubheader}>
          <Box component="div" sx={style.ListSubheaderText}>お知らせ一覧</Box>
        </ListSubheader>
      }
    >
      {treeInformationList.length === 0?
      <div class="LoaderContener"><div class="loader"></div></div>
      :<>
          {treeInformationList.map((treeInformation, index)=>(
            <>
            <ListItemButton 
              className={`${treeInformation.READ_DATE ? "already-read" : false} ${treeInformation.INPOTANT_FLG ? "important-info" : false}`}
              onClick={()=>handleInfoClick(treeInformation.INFO_ID)}
            >
              <ListItemText
                cla
                primary={`${commonFnc.formatDate(treeInformation.CREATE_DATE)}　　${treeInformation.TITLE}`} 
                secondary={treeInformation.NOTE}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            </ListItemButton>
            <Divider/>
            </>
          ))}
          </>
        }
        </List>
      </Box>
  );
}

export default TreeInformationListPage;
