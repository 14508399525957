import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';

import PatientCallList from "../conpornent/PatientCallList";
import '../css/qr/qr.css';
import { Button } from "@mui/material";

function PatientCallListPage(props) {
  
  const {deptgrList, setDeptgrList} = useContext(GlobalContext);
  
  const [selectedDeptgrCd, setSelectedDeptgrCd] = useState('1');

  
  const handleDeptgrButtonClick = (deptgrCd) =>{
    
    setSelectedDeptgrCd(deptgrCd)
  }

  return (
    <div>
      <div className="deptgrButtonArea">
        {
          deptgrList.map((deptgr, index)=>(
            <Button
              className={`clinicButton ${selectedDeptgrCd===deptgr.DEPTGR_CD?'selectedButton':null}`}
              variant="contained"
              onClick={()=>handleDeptgrButtonClick(deptgr.DEPTGR_CD)}
            >
              {deptgr.NAME}
            </Button>
          ))
        }
      </div>
      <h2 style={{"color":"gray","textAlign":"center"}} >呼出済みの番号</h2>
      <PatientCallList
        selectedDeptgrCd={selectedDeptgrCd}
      />
    </div>
  );
}

export default PatientCallListPage;
