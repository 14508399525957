import * as React from 'react';
import { useEffect, useState, useRef, useContext } from "react";
import { GlobalContext } from "../globalState";
import Modal from '@mui/material/Modal';
import { Typography, Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const textFieldStyle = {
  width: '100%',
  margin: '5% auto',
};
const buttonStyle = {
  width: '40%',
  margin: '5%',
};

function EditUserModal(props) {

    const {
      loginUserInfo,
      userEditMode,
      title,
      editUserModalOpen, 
      setEditUserModalOpen,
      displayUser,
      editUserForm,
      setEditUserForm,
      triggerEffect,
      setTriggerEffect,
    } = props;
    
    const handleOpen = () => setEditUserModalOpen(true);
    const handleClose = () => setEditUserModalOpen(false);
    
    const handleOnChange = (e) =>{
      const target = e.target;
      const value = target.value;
      const name = target.name;

      setEditUserForm({
        ...editUserForm,
        [name]:value}
      );

    }
    
    /**
     * 登録ボタンクリック
     * @returns 
     */
    const handleUserEntryButtonClick = async () =>{
      const result = window.confirm('この情報で登録しますか？');
      if(!result) return;
     
      if(userEditMode==='add'){
        await axios.post('tree/api/insertUser', editUserForm);
      }else{
        await axios.post('tree/api/updateUser', editUserForm);
      }
      
      alert(`${editUserForm.firstName}さんの登録が完了しました。`);

      // 情報を再取得して閉じる
      setTriggerEffect(!triggerEffect)
      setEditUserModalOpen(false);
      
    }
    
    /**
     * 削除ボタンクリック
     * @returns 
     */
    const handleUserDeleteButtonClick = async () =>{
      if(!displayUser) return;
      if(loginUserInfo.TREEID === displayUser.TREEID) return; // 自分は削除できない。
      const result = window.confirm(`${displayUser.FIRST_NAME}さんを削除しますか。`);
      if(!result) return;

      const treeId = displayUser.TREEID;

      // ユーザー削除リクエストを送信
      await axios.post('tree/api/deleteUser', { treeId });
      
      alert(`${displayUser.FIRST_NAME}さんを削除しました。`);
      
      // 情報を再取得して閉じる
      setTriggerEffect(!triggerEffect)
      setEditUserModalOpen(false);

    }
    
    return (
      <Modal
        open={editUserModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' , marginBottom:'5%'}}>
            {title}
          </Typography>
          <div>
              <TextField sx={textFieldStyle} type="text" label="氏" name="lastName" variant="standard" 
                value={editUserForm.lastName} 
                onChange={handleOnChange}
                placeholder='三宅'
              />
              <TextField sx={textFieldStyle} type="text" label="名" name="firstName" variant="standard" 
                value={editUserForm.firstName} 
                onChange={handleOnChange}
                placeholder='花子'
              />
          </div>
          <div>
              <TextField
                sx={textFieldStyle}
                type="date"
                label="生年月日"
                name="birthDay"
                variant="standard"
                value={editUserForm.birthDay}
                onChange={handleOnChange}
              />
          </div>
          <div>
              <FormControl sx={{ width: "100%" }}>
                  <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="1"
                  name="sex"
                  value={editUserForm.sex}
                  onChange={handleOnChange}
                  defaultChecked="1"
                  row
                  sx={{ width: "90%" ,margin:'0 auto'}}
                  >
                  <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="男"
                      sx={{ margin:'0 20%'}}
                  />
                  <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="女"
                  />
                  </RadioGroup>
              </FormControl>
          </div>
          <Box>
            <Button sx={buttonStyle} onClick={handleClose}>キャンセル</Button>
            <Button sx={buttonStyle} onClick={handleUserEntryButtonClick}>登録</Button>
          </Box>
          {
            userEditMode!=='edit'?<></>:
            <Box>
              <Button sx={buttonStyle} onClick={handleUserDeleteButtonClick}
              disabled={loginUserInfo.TREEID === displayUser.TREEID}
              >削除</Button>
            </Box>
          }
        </Box>
      </Modal>
    );
}

export default EditUserModal;
