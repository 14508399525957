
import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from '../globalState';


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuItemLink from './MenuItemLink';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import HomeIcon from '@mui/icons-material/Home';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import { Collapse, ListItemButton, ListItemText } from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function MenuDrower(props) {

  const {
    menuDorowerOpen,
    toggleDrawer,
  } = props;

  
  const {loginUserInfo} = useContext(GlobalContext); 
  
  const [open, setOpen] = useState({
    0:true,
    1:false,
    2:false,
    5:false,
    7:false,
});

const handleClick = (num) => {
  setOpen({
      ...open,
      [num]:!open[num]
  });
};
  
  return (
    <Drawer open={menuDorowerOpen} onClose={toggleDrawer(false)}>
      <Box sx={{ width: 300 }} role="presentation" 
        // onClick={toggleDrawer(false)}
        ><List>
        <ListItemButton onClick={()=>handleClick(0)}>
            <ListItemText primary="基本メニュー" />
            {open[0] ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open[0]} timeout="auto" unmountOnExit>
          <MenuItemLink
            icon={<HomeIcon />}
            itemName={'トップ'}
            url={'/'}
          />
          <MenuItemLink
            icon={<PregnantWomanIcon />}
            itemName={'マニュアル'}
            url={'/manuals'}
          />
        </Collapse>
      </List>
        <Divider />
        <List>
          <ListItemButton onClick={()=>handleClick(1)}>
              <ListItemText primary="三宅医院" />
              {open[1] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open[1]} timeout="auto" unmountOnExit>
          <MenuItemLink
            icon={<HomeIcon />}
            itemName={'三宅医院'}
            url={'https://miyake-miyakegroup.jp/'}
          />
          <MenuItemLink
            icon={<LocalConvenienceStoreIcon />}
            itemName={'外来予約'}
            url={'https://a.atlink.jp/miyake/'}
          />
          <MenuItemLink
            icon={<LocalConvenienceStoreIcon />}
            itemName={'ベビーパッド'}
            url={'https://contents.babypad.jp/miyake-o/'}
          />
          <MenuItemLink
            icon={<LocalConvenienceStoreIcon />}
            itemName={'マタニティカレンダー'}
            url={'https://contents.babypad.jp/miyake-o/10979/'}
          />
          </Collapse>
        </List>
        <Divider />
        <List>
          <ListItemButton onClick={()=>handleClick(2)}>
              <ListItemText primary="三宅医院 問屋町テラス" />
              {open[2] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open[2]} timeout="auto" unmountOnExit>
          <MenuItemLink
            icon={<HomeIcon />}
            itemName={'三宅医院 問屋町テラス'}
            url={'https://miyake-miyakegroup.jp/toiyacho/'}
          />
          <MenuItemLink
            icon={<LocalConvenienceStoreIcon />}
            itemName={'外来予約'}
            url={'https://a.atlink.jp/toiyacho/'}
          />
          </Collapse>
        </List>
        <Divider />
        <List>
          <ListItemButton onClick={()=>handleClick(5)}>
              <ListItemText primary="三宅おおふくクリニック" />
              {open[5] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open[5]} timeout="auto" unmountOnExit>
          <MenuItemLink
            icon={<HomeIcon />}
            itemName={'三宅おおふくクリニック'}
            url={'https://ofuku-miyakegroup.jp/'}
          />
          <MenuItemLink
            icon={<LocalConvenienceStoreIcon />}
            itemName={'外来予約'}
            url={'https://yoyaku.atlink.jp/miyakeofuku/'}
          />
          <MenuItemLink
            icon={<AccessibilityIcon />}
            itemName={'人間ドック予約'}
            url={'https://ofuku-miyakegroup.jp/dockform/'}
          />
          <MenuItemLink
            icon={<AccessibilityIcon />}
            itemName={'健診予約'}
            url={'https://ofuku-miyakegroup.jp/dockkenshin/form/reserveform/'}
          />
          <MenuItemLink
            icon={<PersonAddAltIcon />}
            itemName={'オプション検査申込'}
            url={'https://ofuku-miyakegroup.jp/dockkenshin/form/optionform/'}
          />
          </Collapse>
        </List>
        <Divider />
        <List>
          <ListItemButton onClick={()=>handleClick(7)}>
              <ListItemText primary="三宅ハロー歯科" />
              {open[7] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open[7]} timeout="auto" unmountOnExit>
          <MenuItemLink
            icon={<LocalConvenienceStoreIcon />}
            itemName={'外来予約'}
            url={'https://yoyaku.atlink.jp/miyakeofuku/'}
          />
          <MenuItemLink
            icon={<AccessibilityIcon />}
            itemName={'人間ドック予約'}
            url={'https://ofuku-miyakegroup.jp/dockform/'}
          />
          <MenuItemLink
            icon={<AccessibilityIcon />}
            itemName={'健診予約'}
            url={'https://ofuku-miyakegroup.jp/dockkenshin/form/reserveform/'}
          />
          <MenuItemLink
            icon={<PersonAddAltIcon />}
            itemName={'オプション検査申込'}
            url={'https://ofuku-miyakegroup.jp/dockkenshin/form/optionform/'}
          />
          </Collapse>
        </List>
        <Divider />
        <List>
          <MenuItemLink
            icon={<FacebookIcon />}
            itemName={'FaceBook'}
            url={'https://www.facebook.com/miyakeclinicgroup?locale=ja_JP'}
          />
          <MenuItemLink
            icon={<InstagramIcon />}
            itemName={'Instagram 三宅医院グループ'}
            url={'instagram://user?username=miyakeclinicgroup'}
          />
          <MenuItemLink
            icon={<InstagramIcon />}
            itemName={'Instagram ハロー歯科'}
            url={'instagram://user?username=miyake_hello.dental'}
          />
        </List>
        {loginUserInfo?.AUTHORITY>0?
        <>
        <Divider />
        <List>
          <MenuItemLink
            icon={<PregnantWomanIcon />}
            itemName={'お知らせ配信'}
            url={'/admin/treeInformationList'}
          />
        </List>
        <List>
          <MenuItemLink
            icon={<PregnantWomanIcon />}
            itemName={'QRコード呼出'}
            url={'/admin/qrCodeReaderPage'}
          />
        </List>
        <List>
          <MenuItemLink
            icon={<PregnantWomanIcon />}
            itemName={'バーコード呼出'}
            url={'/admin/barCodeReaderPage'}
          />
        </List>
        </>
        :<></>}
      </Box>
    </Drawer>
  );
}

export default MenuDrower;
