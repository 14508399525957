import React, { useState } from 'react';
import { useEffect, useContext } from "react";
import { GlobalContext } from "../globalState";
import QRCodeComponent from '../conpornent/QRCodeComponent';
import Button from '@mui/material/Button';
import { Divider, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import '../css/qr/qr.css'
import EditPstientModalOpen from '../conpornent/EditPstientModalOpen'
import EditUserModal from '../conpornent/EditUserModal';
import CheckIcon from '@mui/icons-material/Check';
import { selectUsersByPatientId } from '../query/selectUsersByPatientId';
import { selectPatientListByFamily } from '../query/selectPatientListByFamily';

function addOneDayToDate(dateString) {
  const originalDate = new Date(dateString);
  originalDate.setDate(originalDate.getDate() + 1);
  return originalDate.toISOString().split('T')[0]; // YYYY-MM-DD形式に変換して返す
}
const formatDate = () => {
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため、1を加えています。2桁表示になるように0埋めしています。
  const day = String(today.getDate()).padStart(2, '0'); // 日付を2桁表示にするため、0埋めしています。

  return `${year}-${month}-${day}`;
}

function QRCodePage() {
 
  const {loginUserInfo} = useContext(GlobalContext);  
  const {deptgrList, setDeptgrList} = useContext(GlobalContext);
  const [family, setFamily] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [patientListView, setPatientListView] = useState([]);
  const [editPatientListForm, setEditPatientListForm] = useState([]);
  const [editUserForm, setEditUserForm] = useState({
    treeId:'',
    firstName:'',
    lastName:'',
    sex:'',
    birthDay:'',
    mainDeptgrCd:'',
    parentId:'',
  });

  const [displayUser, setDisplayUser] = useState();
  const [selectedDeptgrCd, setSelectedDeptgrCd] = useState('1');
  const [editPstientModalOpen, setEditPstientModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [userEditMode, setUserEditMode] = useState('add');

  const [triggerEffect, setTriggerEffect] = useState(false);

  const [title, setTitle] = useState('');

  useEffect(()=>{
    if(!loginUserInfo) {
      setPatientList([]);
      setFamily([]);
      return;
    }

    const fetchFamilyPatientData = async () => {
      const data = await selectPatientListByFamily(loginUserInfo?.TREEID);
      setPatientList(data);
    }
    fetchFamilyPatientData();

    const fetchFamilyData = async () => {
      const data = await selectUsersByPatientId(loginUserInfo?.TREEID);
      setFamily(data);
    }
    fetchFamilyData();

    setSelectedDeptgrCd('1');

  },[triggerEffect, loginUserInfo])

  useEffect(()=>{
    const arr = [];
    family.forEach((user)=>{
      deptgrList.forEach((deptgr)=>{
        const patient = patientList.find(p=>(p.TREEID===user.TREEID && p.DEPTGR_CD===deptgr.DEPTGR_CD));
        const ele = {
          TREEID:user.TREEID,
          DEPTGR_CD:deptgr.DEPTGR_CD,
          PATIENT_ID:patient?patient.PATIENT_ID:null,
          FIRST_NAME:user.FIRST_NAME,
          LAST_NAME:user.LAST_NAME,
          SEX:user.SEX,
          BIRTHDAY:addOneDayToDate(user.BIRTHDAY),
        }
        arr.push(ele);
      })
    })

    setPatientListView(arr);

  },[family, patientList]);

  useEffect(()=>{
    const tmpUser = patientListView.find(p=>p.TREEID===loginUserInfo?.TREEID);
    setDisplayUser(tmpUser);
  },[patientListView])

  // 10桁0埋め
  const padPatientId = (displayUser) =>{
    const ptid = displayUser?.PATIENT_ID ? displayUser.PATIENT_ID : '';
    
    return ptid.toString().padStart(10, '0');
  }

  // QRコード用の文字列作成
  const createQRcodeStr = (selectedDeptgrCd, deptgrList, displayUser) =>{

    const deptgr = deptgrList.find(p=>p.DEPTGR_CD === selectedDeptgrCd);
    const atlinkCd = deptgr?.ATLINK_CD;

    if(!deptgr || !atlinkCd) return '';

    const tmpPtid = padPatientId(displayUser);

    return atlinkCd + "-" + tmpPtid;
  }
  
  // 追加ボタン
  const handleClickAddButton = () => {
    if(!loginUserInfo) return;
    setTitle('新しい家族を登録');
    setUserEditMode('add');

    // 編集用にフォーム情報を登録
    setEditUserForm({
      treeId: null,
      firstName: '',
      lastName: '',
      sex: '',
      birthDay: formatDate(),
      mainDeptgrCd: '',
      parentId: loginUserInfo.TREEID,
    });
    setEditUserModalOpen(true);
  }

  // 編集ボタン
  const handleClickEditButton = () => {
    if(!loginUserInfo) return;
    if(!displayUser) return;
    setTitle('登録内容を編集');
    setUserEditMode('edit');
    // 編集用にフォーム情報を登録
    setEditUserForm({
      treeId: displayUser.TREEID,
      firstName: displayUser.FIRST_NAME,
      lastName: displayUser.LAST_NAME,
      sex: displayUser.SEX,
      birthDay: displayUser.BIRTHDAY,
      mainDeptgrCd: displayUser.MAIN_DEPTGR_CD,
      parentId: loginUserInfo.TREEID,
    });
    setEditUserModalOpen(true);
  }
    
  // 診察券番号登録ボタン
  const handleClickPatientIdButton = () => {
    if(!loginUserInfo) return;
    if(!displayUser) return;
    setEditPatientListForm(patientListView.filter(p=>p.TREEID === displayUser.TREEID).map(p=>({...p})))
    setEditPstientModalOpen(true);
  }
    
  const handleDeptgrButtonClick = (deptgrCd) =>{
    
    const displayUserId = displayUser?.TREEID;
    const user = patientListView.find(p=>(p.DEPTGR_CD===deptgrCd && p.TREEID === displayUserId));
    setDisplayUser(user);
    setSelectedDeptgrCd(deptgrCd)
  }

  // リスト選択
  const handleButtonClick = (user) => {
    setDisplayUser(user);
  }

  // 再読込ボタン
  const handleReloadButtonClick = () => {
    setTriggerEffect(!triggerEffect);
  }

  console.log(loginUserInfo)
  console.log(patientListView)

  return (
    <>
      {!loginUserInfo && patientListView.length === 0 ? <div class="LoaderContener"><div class="loader"></div></div> :
      <div class="qrPage">
        <div class="qrArea">
          <h3>デジタル診察券</h3>
            {
              !loginUserInfo?<h4>ログインしていません。</h4>:
              displayUser ?
              <>
              {!displayUser.PATIENT_ID?<p>未登録</p>:
              <>
                <p class="barCode">A{padPatientId(displayUser)}A</p>
                </>
              }
                <div class="qrCode">
                  {!displayUser.PATIENT_ID?<></>:
                    <QRCodeComponent data={displayUser?.PATIENT_ID? createQRcodeStr(selectedDeptgrCd, deptgrList, displayUser) :''}/>
                  }
                </div>
                <p class="patientInfo">{displayUser?.PATIENT_ID}　{`${displayUser?.LAST_NAME} ${displayUser?.FIRST_NAME}`}</p>
              </> :
              <h4>選択されていません。</h4>
            }
        </div>
        <div style={{display:'flex'}}>
          <Button sx={{width:'50%'}} onClick={handleReloadButtonClick} disabled={!loginUserInfo}>再読込</Button>
          <Button sx={{width:'50%'}} onClick={handleClickAddButton} disabled={!loginUserInfo}>家族を追加</Button>
          <Button sx={{width:'50%'}} onClick={handleClickEditButton} disabled={!loginUserInfo}>情報を編集</Button>
          <Button sx={{width:'50%'}} onClick={handleClickPatientIdButton} disabled={!loginUserInfo}>診察券登録</Button>
        </div>
        <div className="deptgrButtonArea">
          {
            deptgrList.map((deptgr, index)=>(
              <Button
                className={`clinicButton ${selectedDeptgrCd===deptgr.DEPTGR_CD?'selectedButton':null}`}
                variant="contained"
                onClick={()=>handleDeptgrButtonClick(deptgr.DEPTGR_CD)}
              >
                {deptgr.NAME}
              </Button>
            ))
          }
        </div>
        <div className="buttonArea">
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {
            patientListView.filter((p)=>p.DEPTGR_CD===selectedDeptgrCd).map((user, index) => {
              return (
                <>
                  <ListItem>
                    <ListItemButton
                      onClick={()=>handleButtonClick(user)}
                    >
                      {displayUser?.TREEID === user?.TREEID?
                      <ListItemIcon>
                        <CheckIcon sx={{color:'#4dc174'} }/>
                      </ListItemIcon>
                      :<></>
                      }
                      <div class="listItem">
                        <div class="listItemEle">{user.PATIENT_ID?user.PATIENT_ID:'未登録'}</div>
                        <div class="listItemEle">{`${user.LAST_NAME} ${user.FIRST_NAME}`}</div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              );
            })
          }
          </List>
        </div>
      </div>
      }
      <EditUserModal
        loginUserInfo={loginUserInfo}
        userEditMode={userEditMode}
        title={title}
        editUserModalOpen={editUserModalOpen}
        setEditUserModalOpen={setEditUserModalOpen}
        displayUser={displayUser}
        editUserForm={editUserForm}
        setEditUserForm={setEditUserForm}
        triggerEffect={triggerEffect}
        setTriggerEffect={setTriggerEffect}
      />
      <EditPstientModalOpen
        editPstientModalOpen={editPstientModalOpen}
        setEditPstientModalOpen={setEditPstientModalOpen}
        displayUser={displayUser}
        editPatientListForm={editPatientListForm}
        setEditPatientListForm={setEditPatientListForm}
        deptgrList={deptgrList}
        triggerEffect={triggerEffect}
        setTriggerEffect={setTriggerEffect}
      />
    </>
  );
};

export default QRCodePage;