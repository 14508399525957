/**
 * 対象者の家族の診察券番号一覧を取得する。
 * @returns 
 */
export async function selectPatientListByFamily(parentId) {
    const query = new URLSearchParams({parentId: parentId});
    const url = `tree/api/selectPatientListByFamily?${query}`;
    const res = await fetch(url);
    const data = await res.json();
    return data;
}