import * as React from 'react';
import { useEffect, useState, useContext } from "react";
import Modal from '@mui/material/Modal';
import { Box, Button, TextField, Typography } from '@mui/material';
import { GlobalContext } from "../globalState";
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const textFieldStyle = {
  width: '100%',
  margin: '5% auto',
};
const buttonStyle = {
  width: '40%',
  margin: '5%',
};


function EditPstientModalOpen(props) {

    const {
      editPstientModalOpen, 
      setEditPstientModalOpen,
      displayUser,
      editPatientListForm,
      setEditPatientListForm,
      deptgrList,
      triggerEffect,
      setTriggerEffect,
    } = props;

    const {loginUserInfo} = useContext(GlobalContext);  

    const handleOpen = () => setEditPstientModalOpen(true);
    const handleClose = () => setEditPstientModalOpen(false);
    
    const handleChangePatientId = (e, deptgrCd) =>{
      const value = e.target.value;
      let copiedPatientList = editPatientListForm.map(pt => ({ ...pt }));

      const index = copiedPatientList.findIndex(pt => pt.DEPTGR_CD === deptgrCd);
      if (index !== -1) {
        copiedPatientList[index] = { ...copiedPatientList[index], PATIENT_ID: value };
      }
      
      setEditPatientListForm(copiedPatientList);
    }

    const handleSubmitButtonClick = async () => {
      const result = window.confirm('この情報で登録しますか？');
      if(!result) return;
     
      // ユーザー登録リクエストを送信
      await axios.post('tree/api/upsertPatientByUser', { patientList:editPatientListForm });
      alert(`${displayUser.FIRST_NAME}さんの登録が完了しました。`);

      // 情報を再取得
      setTriggerEffect(!triggerEffect);

      setEditPstientModalOpen(false);
    }
    
    
    return (
        <Modal
          open={editPstientModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' , marginBottom:'5%'}}>
              診察券番号を登録
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 , textAlign:'center', marginBottom:'10%'}}>
              {`${displayUser?.LAST_NAME} ${displayUser?.FIRST_NAME}`}
            </Typography>
            {
              !deptgrList?<></>:
              deptgrList.map((deptgr)=>(
                <TextField 
                  sx={textFieldStyle}
                  type="text"
                  label={deptgr.NAME}
                  placeholder='999999'
                  value={editPatientListForm.find(p=>(p.DEPTGR_CD === deptgr.DEPTGR_CD))?
                    editPatientListForm.find(p=>(p.DEPTGR_CD === deptgr.DEPTGR_CD)).PATIENT_ID:
                    ''}
                  onChange={(e)=>{handleChangePatientId(e, deptgr.DEPTGR_CD)}}
                />
              ))
            }
            <Box>
              <Button sx={buttonStyle} onClick={handleClose}>キャンセル</Button>
              <Button sx={buttonStyle} onClick={handleSubmitButtonClick}>登録</Button>
            </Box>
          </Box>
        </Modal>
    );
}

export default EditPstientModalOpen;
