import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';
import treeInformationClass from "../classes/treeInformationClass";
import { Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, Switch, TextField } from "@mui/material";
import axios from 'axios';
import { selectTreeInformation } from "../query/selectTreeInformation";
import { useNavigate } from "react-router-dom";


const style = {
  BoxStyle:{
    Width:'100%',
    padding:'5%',
  },
  TextFieldStyle:{
    width:"100%",
    margin:'0 auto 10px auto',
  },
  ButtonStyle:{
    width:"42%",
    margin:'10px 4%',
  },
  FormControl:{
    width:"100%",
    border:"1px solid #d4d4d1"
  }
}


/**
 * TREEお知らせ情報を登録画面。
 * @param {*} props
 * @returns 
 */
function TreeInformationFormAdminPage(props) {

  const navigate = useNavigate();

  const {selectedTreeInformationId, setSelectedTreeInformationId} = useContext(GlobalContext);
  const [treeInformation, setTreeInformation] = useState(null);

  useEffect(()=>{
    
    const fetchTreeInformation = async ()=>{
      if(selectedTreeInformationId){
        setTreeInformation(await selectTreeInformation(selectedTreeInformationId));
      }else{
        setTreeInformation(new treeInformationClass());
      }
      
    }
    fetchTreeInformation();

  },[])


  
  const handleOnChange = (e) =>{
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setTreeInformation({
      ...treeInformation,
      [name]:value
    })

  }

  const handleOnChangeSwitch = (e) => {
    const target = e.target;
    const name = target.name;
    const checked = target.checked;

    setTreeInformation({
      ...treeInformation,
      [name]:checked
    });
  };

  const handleSubmitButtonClick = async () =>{
    const result = window.confirm('この情報で登録しますか？');
    if(!result) return;
   
    // ユーザー登録リクエストを送信
    try {
      if(!treeInformation.INFO_ID){
        await axios.post('/tree/api/insertTreeInformation', { treeInformation:treeInformation });  
        alert(`登録が完了しました。`);
        navigate('/admin/treeInformationList');
      }else{
        await axios.post('/tree/api/updateTreeInformation', { treeInformation:treeInformation });  
        alert(`更新が完了しました。`);
        navigate('/admin/treeInformationList');
      }
    } catch (error) {
      alert(error);
    }
    
  }

  const handleCancelButtonClick = () =>{
    setSelectedTreeInformationId(null);
    navigate('/admin/treeInformationList');

  }

  return (
      <Box sx={style.BoxStyle}>
      {!treeInformation?<></>:<>
        <TextField
          sx={style.TextFieldStyle}
          variant="standard"
          disabled
          type="text"
          label="info id"
          name="INFO_ID"
          value={treeInformation.INFO_ID}
          placeholder='EX:) 〇〇について'
          onChange={(e)=>handleOnChange(e)}
        />
        <TextField
          sx={style.TextFieldStyle}
          type="text"
          label="タイトル"
          name="TITLE"
          value={treeInformation.TITLE}
          placeholder='EX:) 〇〇について'
          onChange={(e)=>handleOnChange(e)}
        />
        <TextField
          sx={style.TextFieldStyle}
          type="text"
          multiline
          rows={13}
          label="内容"
          name="NOTE"
          value={treeInformation.NOTE}
          placeholder='お知らせする内容を記入してください。'
          onChange={(e)=>handleOnChange(e)}
        />
        <TextField
          sx={style.TextFieldStyle}
          type="date"
          label="FROM"
          name="FROM_DATE"
          value={treeInformation.FROM_DATE}
          onChange={(e)=>handleOnChange(e)}
        />
        <TextField
          sx={style.TextFieldStyle}
          type="date"
          label="TO"
          name="TO_DATE"
          value={treeInformation.TO_DATE}
          onChange={(e)=>handleOnChange(e)}
        />
        <FormControl component="fieldset" sx={style.FormControl}>
          <FormLabel component="legend">設定</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Switch 
                  color="primary"
                  name="INPOTANT_FLG"
                  checked={treeInformation.INPOTANT_FLG}
                  onChange={(e)=>handleOnChangeSwitch(e)}
                />}
              label="重要"
              labelPlacement="top"
            />
            <FormControlLabel
              control={
                <Switch 
                  color="primary"
                  name="MODAL_VIEW_FLG"
                  checked={treeInformation.MODAL_VIEW_FLG}
                  onChange={(e)=>handleOnChangeSwitch(e)}
                />}
              label="モーダル"
              labelPlacement="top"
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  name="IS_VIEW_FLG"
                  checked={treeInformation.IS_VIEW_FLG}
                  onChange={(e)=>handleOnChangeSwitch(e)}
                />}
              label="公開"
              labelPlacement="top"
            />
          </FormGroup>
        </FormControl>
        <div>
          <Button 
            sx={style.ButtonStyle}
            variant="contained"
            onClick={()=>handleCancelButtonClick()}
          >
              キャンセル
          </Button>
          <Button 
            sx={style.ButtonStyle}
            variant="contained"
            onClick={()=>handleSubmitButtonClick()}
          >
              登録
          </Button>
        </div>
        </>}
      </Box>
  );
}

export default TreeInformationFormAdminPage;
