import { useEffect, useState, useRef, useContext } from "react";
import { GlobalContext } from "../globalState";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import '../css/top/mainMenuButton.css';


function Top(props) {
    const navigate = useNavigate();

    const loginUserInfo = useContext(GlobalContext).loginUserInfo;
    const [loginUserInfoView, setLoginUserInfoView] = useState(loginUserInfo);

    useEffect(()=>{
        setLoginUserInfoView(loginUserInfo);
    },[loginUserInfo])
    
    return (
        <Box className='sectionWrapper'>
            <section class="subSection">
                <div class="contentWrapper">
                    <div class="contentsArea">
                        <a onClick={()=>navigate('viewHp')} class='menuButton subMenuButton primary'><img src="/img/icon/hp_icon.png" alt="hp"/></a>
                        <a onClick={()=>navigate('reserve')} class='menuButton subMenuButton secondary'><img src="/img/icon/reserve_icon.png" alt="reserve"/></a>
                        <a onClick={()=>navigate('dockReserve')} class='menuButton subMenuButton tertiary'><img src="/img/icon/dock_icon.png" alt="dock"/></a>
                        <a onClick={()=>navigate('viewReserveInfo')} class='menuButton subMenuButton secondary'><img src="/img/icon/guide_icon.png" alt="guide"/></a>
                        <a onClick={()=>navigate('maintenance')} class='menuButton subMenuButton tertiary'><img src="/img/icon/map_icon.png" alt="map"/></a>
                        <a onClick={()=>navigate('bookshelf')} class='menuButton subMenuButton secondary'><img src="/img/icon/papers_icon.png" alt="papers"/></a>
                        <a onClick={()=>navigate('treeInformationList')} class='menuButton subMenuButton tertiary'><img src="/img/icon/news_icon.png" alt="news"/></a>
                        <a onClick={()=>navigate('viewWEBMon')} class='menuButton subMenuButton secondary'><img src="/img/icon/webMon_icon.png" alt="webMon"/></a>
                        <a onClick={()=>navigate('viewInfomation')} class='menuButton subMenuButton primary'><img src="/img/icon/info_icon.png" alt="info"/></a>
                    </div>
                </div>
            </section>
            <section class="mainSection">
                <div class="contentWrapper mainContentsWrapper">
                    <div class="contentsArea">
                        <a onClick={()=>navigate('qr')} class='menuButton mainMenuButton '><img src="/img/icon/qr_icon.png" alt="kyousei"/></a>
                        <a onClick={()=>navigate('paymentsCallList')} class='menuButton mainMenuButton '><img src="/img/icon/paymentCall_icon.png" alt="payment-call"/></a>
                    </div>
                </div>
            </section>
            <section class="bunnerSection">
                <div class="contentWrapper">
                    <div class="contentsArea">
                        <a onClick={()=>{window.location.href='https://miyake-miyakegroup.jp/child/info/#oshin'}} class='menuButton bunnerMenuButton primary'><img src="/img/banner/banner_shouni.png" alt="baby"/></a>
                        <a onClick={()=>navigate('galleryDishes')} class='menuButton bunnerMenuButton primary'><img src="/img/banner/banner_atlink.png" alt="deshies"/></a>
                        <a onClick={()=>{window.location.href='https://miyake-miyakegroup.jp/toiyacho/datsumo/'}} class='menuButton bunnerMenuButton primary'><img src="/img/banner/banner_datsumo_vio.png" alt="baby"/></a>
                        <a onClick={()=>{window.location.href='https://sites.google.com/view/dockticket/home'}} class='menuButton bunnerMenuButton primary'><img src="/img/banner/banner_dockticket.png" alt="deshies"/></a>
                    </div>
                </div>
            </section>
        </Box>
    );
}

export default Top;
