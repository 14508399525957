import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';

import '../css/viewIFrame/viewIFrame.css';

import ViewIFrame from '../conpornent/ViewIFrame';
import SummaryButton from "../conpornent/SummaryButton";

function SummryInfomationPage(props) {
  
  const envInfomationUrl = useContext(GlobalContext).envInfomationUrl;
  const {iframeUrl, setIframeUrl} = useContext(GlobalContext);
  
  const [seletedHp, setSelectedHp] = useState('MC');

  useEffect(()=>{
    setIframeUrl(envInfomationUrl.MCURL);
  },[])


  return (
    <div>
      <div class="buttonArea">
        <SummaryButton 
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='三宅医院'
          clinicStr='MC'
          url={envInfomationUrl.MCURL}
        />
        <SummaryButton 
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='おおふく'
          clinicStr='MOC'
          url={envInfomationUrl.MOCURL}
        />
        <SummaryButton
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='テラス'
          clinicStr='MCTT'
          url={envInfomationUrl.MCTTURL}
        />
        <SummaryButton 
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='ハロー'
          clinicStr='HELLO'
          url={envInfomationUrl.HELLOURL}
        />
      </div>
      <ViewIFrame
        iframeUrl={iframeUrl}
      />
    </div>
  );
}

export default SummryInfomationPage;
