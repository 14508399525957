
import React, { useContext } from 'react';
import { GlobalContext } from '../globalState';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CardList from '../conpornent/CardList';

function AtlinkWEBMonshinListPage() {

  const envWebMonshin = useContext(GlobalContext).envWebMonshin;

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <CardList
        url={envWebMonshin.MCWEBMONSHINURL}
        title={envWebMonshin.MCTITLE}
        subTitle={envWebMonshin.MCSUBTITLE}
        discription={envWebMonshin.MCDISCRIPTION}
        img={envWebMonshin.MCIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envWebMonshin.MOCWEBMONSHINURL}
        title={envWebMonshin.MOTITLE}
        subTitle={envWebMonshin.MOCSUBTITLE}
        discription={envWebMonshin.MOCDISCRIPTION}
        img={envWebMonshin.MOCIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envWebMonshin.MCTTWEBMONSHINURL}
        title={envWebMonshin.MCTTTITLE}
        subTitle={envWebMonshin.MCTTSUBTITLE}
        discription={envWebMonshin.MCTTDISCRIPTION}
        img={envWebMonshin.MCTTIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envWebMonshin.HELLOWEBMONSHINURL}
        title={envWebMonshin.HELLOTITLE}
        subTitle={envWebMonshin.HELLOSUBTITLE}
        discription={envWebMonshin.HELLODISCRIPTION}
        img={envWebMonshin.HELLOIMG}
      />
    </List>
  );
}

export default AtlinkWEBMonshinListPage;
