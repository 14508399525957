


// ログイン時にローカルストレージにログイン情報を保存する関数
export function login (userInfo){
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

// ログアウト時にローカルストレージからログイン情報を削除する関数
export function logout (){
    localStorage.removeItem('userInfo');
};

// アプリの起動時にローカルストレージからログイン情報を読み込む関数
export function loadUserInfo (loginUserInfo, setLoginUserInfo){
    
    if(!loginUserInfo){
        const userInfoString = localStorage.getItem('userInfo');
        setLoginUserInfo(userInfoString ? JSON.parse(userInfoString) : null);
    }
    
};

// ログイン情報を取得するコンポーネント
export function UserInfoComponent (){
    const userInfo = loadUserInfo();

    if (userInfo) {
        return <div>Welcome, {userInfo.username}</div>;
    } else {
        return <div>Please log in</div>;
    }
};