import React, { useContext } from 'react';
import { GlobalContext } from '../globalState';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CardList from '../conpornent/CardList';

function DockKenshinReserveListPage(props) {

  const envDockReserve = useContext(GlobalContext).envDockReserve;

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <CardList
        url={envDockReserve.DOCKURL}
        title={envDockReserve.DOCKTITLE}
        subTitle={envDockReserve.DOCKSUBTITLE}
        discription={envDockReserve.DOCKDISCRIPTION}
        img={envDockReserve.DOCKIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envDockReserve.KENSHIURL}
        title={envDockReserve.KENSHINTITLE}
        subTitle={envDockReserve.KENSHINSUBTITLE}
        discription={envDockReserve.KENSHINDISCRIPTION}
        img={envDockReserve.KENSHINIMG}
      />
      <Divider variant="inset" component="li" />
      <CardList
        url={envDockReserve.OPTIONURL}
        title={envDockReserve.OPTIONTITLE}
        subTitle={envDockReserve.OPTIONSUBTITLE}
        discription={envDockReserve.OPTIONDISCRIPTION}
        img={envDockReserve.OPTIONIMG}
      />
    </List>
  );
}

export default DockKenshinReserveListPage;
