
import axios from 'axios';
/**
 * メールアドレスとPWからユーザ情報を取得する。
 * @param {*} mail メールアドレス
 * @param {*} pw パスワード
 * @returns 
 */
export async function selectUserMailPw(mail, pw) {

    const res = await axios.post('/tree/api/selectUserMailPw', { mail, pw });
    const dataList = res.data;
    return dataList

}