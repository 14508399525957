import '../css/signin/signIn.css'
import SingIn from "../conpornent/SignIn";
const SignInPage = (props) =>{
    
    return (
        <div>
            <div class="signIn_header"/>
            <div class="signin_wrapper">
                <div class="signin_content">
                    <SingIn/>
                </div>
            </div>
        </div>
    );
}

export default SignInPage;