import { Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { GlobalContext } from "../globalState";

const BookShelfPage = (props) =>{

    const {deptgrList} = useContext(GlobalContext);

    const navigate = useNavigate();
    const [books, setBooks] = useState([]);
    const [open, setOpen] = useState({
        1:false,
        2:false,
        5:false,
        7:false,
    });

    const imgDic = {
        1:'img/department/mc.png',
        2:'img/department/mctt.png',
        5:'img/department/moc.png',
        7:'img/department/hello.png',
    }

    useEffect(()=>{
        const fetchData = async () => {
            const res = await fetch('tree/api/selectBooks');
            const data = await res.json();
            setBooks(data);
        }
        fetchData();
    },[])

    const handleClick = (num) => {
        setOpen({
            ...open,
            [num]:!open[num]
        });
    };

    const handleOncliickBookLink = (book) => {
        window.open(book.URL, '_blank');
    }
    
    return (
        <div>
            {
                deptgrList.map((deptgr, index)=>(
                    <List sx ={{width:'90%', margin:'0 auto'}}>
                        <ListItemButton 
                            onClick={()=>handleClick(deptgr.DEPTGR_CD)} 
                            sx={{backgroundColor:"snow"}} 
                            key={index}
                        >
                            <ListItemIcon sx={{height:100, marginRight:5}}>
                                <img src={imgDic[deptgr.DEPTGR_CD]}/>
                            </ListItemIcon>
                            <ListItemText primary={deptgr.NAME} />
                            {open[deptgr.DEPTGR_CD] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open[deptgr.DEPTGR_CD]} timeout="auto" unmountOnExit>
                        {
            
                            books.filter(p=>p.DEPTGR_CD === deptgr.DEPTGR_CD).map((book, index)=>(
                                <List component='div' disablePadding>
                                    <ListItemButton
                                        onClick={()=>handleOncliickBookLink(book)}
                                        sx={{ pl: 4 }}
                                        key={index}
                                    >
                                        <ListItemIcon>
                                            <BookmarkBorderIcon/>
                                        </ListItemIcon>
                                        <ListItem>
                                            {book.NAME}
                                        </ListItem>
                                    </ListItemButton>
                                </List>
                            ))
                        }
                        </Collapse>
                        <Divider/>
                    </List>
                ))
            }
        </div>
    );
}

export default BookShelfPage;