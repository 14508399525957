/**
 * 全てのTREEお知らせ一覧を取得する
 * @returns 
 */
export async function selectTreeInformationIsViewList(infoId) {
    const query = new URLSearchParams({infoId: infoId});
    const url = `/tree/api/selectTreeInformationIsViewList?${query}`;
    const res = await fetch(url);
    const data = await res.json();
    return data;
}