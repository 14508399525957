/**
 * 呼出情報を取得する
 * @returns 
 */
export async function selectCallList(selectedDeptgrCd, dispDisplay) {
    
    const query = new URLSearchParams({deptgrCd: selectedDeptgrCd, display:dispDisplay});
    const url = `/tree/api/selectCallList?${query}`;
    const res = await fetch(url);
    const data = await res.json();
    return data;
}