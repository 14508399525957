/**
 * 対象者の家族のユーザ情報一覧を取得する。
 * @returns 
 */
export async function selectUsersByPatientId(patientId) {
    const query = new URLSearchParams({patientId: patientId});
    const url = `tree/api/selectUsersByPatientId?${query}`;
    const res = await fetch(url);
    const data = await res.json();
    return data;
}