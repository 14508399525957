/**
 * メールアドレスからユーザ情報を取得する。
 * @param {*} mail メールアドレス
 * @returns 
 */
export async function selectUserMail(mail) {
    const query = new URLSearchParams({mail: mail});
    const url = `/tree/api/selectUser?${query}`;
    const res = await fetch(url);
    const data = await res.json();
    return data;
}