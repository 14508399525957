
import * as React from 'react';
import {useState, useEffect, useContext} from "react";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

import MenuDrower from './MenuDrower';
import { GlobalContext } from "../globalState";
import * as BL_LoadLocalStrage from '../bl/BL_LoadLocalStrage';
import '../css/appBar/appBar.css';

const CustomAppBar = styled(AppBar)({
  backgroundImage: 'url("/img/header.png")', // 画像のパスを指定
  backgroundSize: 'cover',
});

function Appbar(props) {

  const {iframeUrl, setIframeUrl} = useContext(GlobalContext);
  const {loginUserInfo, setLoginUserInfo} = useContext(GlobalContext);
  const navigate = useNavigate();

  const [menuDorowerOpen, setMenuDroweOpen] = useState(false);  
  const toggleDrawer = (newOpen) => () => {
      setMenuDroweOpen(newOpen);
  };

  const handleLoginButonClick = () => {
    navigate('/SignIn');
  }
  const handleLogoutButonClick = () => {
    const result = window.confirm('ログアウトしますか？');
    if(result){
      BL_LoadLocalStrage.logout();
      setLoginUserInfo(null);
    }
  }

  useEffect(()=>{
    BL_LoadLocalStrage.loadUserInfo(loginUserInfo, setLoginUserInfo);
  },[])
  

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} className='app-bar'>
      <CustomAppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2}}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <div className="headerContentArea" href="/">
            <img class="headerLogo" src="/img/headerLogo.png" onClick={()=>navigate("/")}></img>
          </div>
          <div>
            {loginUserInfo ? 
              <Button color="inherit" className="loginButon logout" onClick={()=>handleLogoutButonClick()}>ログアウト</Button>
            :
              <Button color="inherit" className="loginButon login" onClick={()=>handleLoginButonClick()}>ログイン</Button>
            }
          </div>
        </Toolbar>
      </CustomAppBar>
      <MenuDrower
        menuDorowerOpen={menuDorowerOpen}
        toggleDrawer={toggleDrawer}
        setIframeUrl={setIframeUrl}
      />
    </Box>
  );
}

export default Appbar;
