import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function NoticeList(props) {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    };

    return (
    <div>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
        >
            {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
            2024-03-29
            </Typography> */}
            <Typography sx={{ color: 'text.secondary' }}>費用は発生するの？</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
            子ども医療費受給資格者証
            お子さまの医療費の窓口負担が０円になる証明書です。
            ※対象期間は市町村によって異なります。
            この証明証を保険証と一緒に毎月最初の診察時に受付窓口へご提出ください。

            各種無料券
            親子手帳（母子健康手帳）と一緒に交付される受診票（別冊）は乳幼児健診などで ご利用いただけます。
            ご利用の際は、来院時に受付窓口に ご提出ください。
            </Typography>
        </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
        >
            {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
                2024-03-23
            </Typography> */}
            <Typography sx={{ color: 'text.secondary' }}>
            本当に家まで来てくれるの？
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
            はい。三宅医院　8キロ圏内であれば医師、および看護スタッフがご自宅まで伺います。
            </Typography>
        </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
        >
            {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
            2024-03-16
            </Typography> */}
            <Typography sx={{ color: 'text.secondary' }}>
            診察だけでしょうか？薬もすぐ欲しいのだけど。
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
            ご安心ください。ドクターが処方が必要と認めた場合、その場でお薬をお渡しします。
            </Typography>
        </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
        >
            {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
            2024-03-01
            </Typography> */}
            <Typography sx={{ color: 'text.secondary' }}>
            誰でも受けられるの？
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
            申し訳ありません。三宅医院の小児科を受診経験のあるお子様のみを対象をさせていただいております。未受診の場合は本サービスをご利用いただけませんのでご了承ください。まずは日中に診察にお越しください。
            </Typography>
        </AccordionDetails>
        </Accordion>
    </div>
    );
}

export default NoticeList;
