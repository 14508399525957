import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';
import treeInformationClass from "../classes/treeInformationClass";
import { Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, Switch, TextField } from "@mui/material";
import axios from 'axios';
import { selectTreeInformation } from "../query/selectTreeInformation";
import { useNavigate } from "react-router-dom";
import * as commonFnc from '../bl/BL_common';


const style = {
  BoxStyle:{
    Width:'100%',
    padding:'5%',
  },
  TextFieldStyle:{
    width:"100%",
    margin:'0 auto 10px auto',
  },
  TextFieldInputStyle:{
    readOnly:'true',
  },
  ButtonStyle:{
    width:"42%",
    margin:'10px 4%',
  },
  FormControl:{
    width:"100%",
    border:"1px solid #d4d4d1"
  }
}


/**
 * TREEお知らせ情報を登録画面。
 * @param {*} props
 * @returns 
 */
function TreeInformationFormPage(props) {

  const navigate = useNavigate();

  const {loginUserInfo} = useContext(GlobalContext);  

  const {selectedTreeInformationId, setSelectedTreeInformationId} = useContext(GlobalContext);
  const [treeInformation, setTreeInformation] = useState(null);

  useEffect(()=>{

    if(!selectedTreeInformationId){
      navigate('/treeInformationList');
    }
    
    const fetchTreeInformation = async ()=>{
      if(selectedTreeInformationId){
        setTreeInformation(await selectTreeInformation(selectedTreeInformationId));
      }else{
        setTreeInformation(new treeInformationClass());
      }
      
    }
    fetchTreeInformation();

  },[])


  // 既読
  const handleReadInfomationVuttonClick = async () =>{
    
    if(!loginUserInfo.TREEID) return;

    const now = new Date();

    const readInfo = {
      treeId:loginUserInfo.TREEID,
      infoId:treeInformation.INFO_ID,
      readDate:commonFnc.formatDate(now),
      readTime:commonFnc.formatTime(now),
    }

    await axios.post('/tree/api/upsertTreeInformationAlreadyRead', { readInfo:readInfo });
  }

  const handleCancelButtonClick = () =>{
    setSelectedTreeInformationId(null);
    navigate('/treeInformationList');

  }

  return (
      <Box sx={style.BoxStyle}>
      {!treeInformation?<></>:<>
        <TextField
          sx={style.TextFieldStyle}
          variant="standard"
          disabled
          type="text"
          label="info id"
          name="INFO_ID"
          value={treeInformation.INFO_ID}
        />
        <TextField
          sx={style.TextFieldStyle}
          InputProps={style.TextFieldInputStyle}
          type="text"
          label="タイトル"
          name="TITLE"
          value={treeInformation.TITLE}
        />
        <TextField
          sx={style.TextFieldStyle}
          InputProps={style.TextFieldInputStyle}
          type="text"
          multiline
          rows={13}
          label="内容"
          name="NOTE"
          value={treeInformation.NOTE}
        />
        <div>
          <Button 
            sx={style.ButtonStyle}
            variant="contained"
            onClick={()=>handleCancelButtonClick()}
          >
              戻る
          </Button>
          <Button 
            sx={style.ButtonStyle}
            variant="contained"
            onClick={()=>handleReadInfomationVuttonClick()}
            disabled={!loginUserInfo}
          >
              既読
          </Button>
        </div>
        </>}
      </Box>
  );
}

export default TreeInformationFormPage;
