import React, { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';
import '../css/barCodeReader/barCodeReaderPage.css';
import axios from 'axios';
import * as commonFnc from '../bl/BL_common';
import useSound from 'use-sound';
import SoundOk from '../audio/touch_ok2.mp3';
import SoundNg from '../audio/touch_ng1.mp3';

const deptgrHash = {
  M00133: '2',
  M00233: '1',
  M00333: '5',
  M00433: '7',
};

function extractNumber(str) {
  if (typeof str !== 'string') {
    return "";
  }
  const match = str.match(/A\d+(\d{5})A/);
  if (!match) {
    return "";
  }
  return match[1];
}

function BarCodeScannerPage() {
  
  const [playOk] = useSound(SoundOk);
  const [playNg] = useSound(SoundNg);

  const [isScanning, setIsScanning] = useState(true);
  const [result, setResult] = useState("no result");
  const [location] = useState('おおふく受付');
  const [display] = useState('display01');
  
  const SCAN_DELAY = 3000; // 3秒間隔
  let isScanLocked = useRef(false);
  
  const postCallData = async (args) => {
      await axios.post('/tree/api/upsertCall', { args });
  };

  useEffect(() => {
    Quagga.init({
      inputStream: {
        name: 'Live',
        type: 'LiveStream',
        target: document.querySelector('#barcode-scanner'),
        constraints: {
          facingMode: 'environment'
        },
      },
      decoder: {
        readers: ['codabar_reader'], // NW7(Codabar)形式のバーコードをサポート
      },
    }, function (err) {
      if (err) {
        console.error(err);
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected(async (data) => {
      if (isScanLocked.current) {
        return; // 3秒間は次の読み取りを無効化
      }
    
      isScanLocked.current = true; // ロックをかける
    
      playOk(); // 音を鳴らす
    
      const today = new Date();
      const callDate = commonFnc.formatDate(today);
      const callTime = commonFnc.formatTime(today);
      const patientId = extractNumber(data.codeResult.code);
    
      const args = ['5', location, display, patientId, callDate, callTime, false];
      await postCallData(args);
      setResult(data.codeResult.code);
    
      // 3秒後にロックを解除
      setTimeout(() => {
        isScanLocked.current = false;
        // setResult("no result");
      }, SCAN_DELAY);
    });

    return () => {
      Quagga.stop(); // コンポーネントがアンマウントされた時に停止
    };
  // }, [isScanning, playOk]);
  }, [playOk]);

  // useEffect(()=>{
  //   playOk(); // 音を鳴らす
  // },[playOk])
  
  playOk(); // ここで1度鳴らさないと読み込み時にも音がならない


  return (
    <div style={{textAlign:"center"}}>
      <h1>バーコード呼出</h1>
      <h3>{result}</h3>
      <div id="barcode-scanner" style={{ width: '85%', height: '50%', margin:'0 auto' ,'object-fit': 'cover'}}></div>
    </div>
  );
}

export default BarCodeScannerPage;
