/**
 * 全てのTREEお知らせ一覧を取得する
 * @returns 
 */
export async function selectTreeInformation(infoId) {
    const query = new URLSearchParams({infoId: infoId});
    const url = `/tree/api/selectTreeInformation?${query}`;
    const res = await fetch(url);
    const data = await res.json();
    return data[0];
}