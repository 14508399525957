import React, { useState, useEffect, useContext } from 'react';

function FirstVisitLogo(props) {
    const {
      sessionTagName,
      img
    } = props;
  
    const [logoState, setLogoState] = useState('');
    
    // 初回起動時にロゴを表示する。
    useEffect(() => {
      
      const isFirstVisitSession = sessionStorage.getItem(sessionTagName);
      if (!isFirstVisitSession) {
        sessionStorage.setItem(sessionTagName, 'false');
  
        setTimeout(()=>{
          setLogoState('fade-in'); // フェードイン
  
          setTimeout(()=>{
            setLogoState('fade-out'); // フェードアウト
    
            setTimeout(()=>{
              setLogoState('finished'); // 非表示
            },2000);
            
          },2000);
        },0);
  
  
      }else{
        setLogoState('finished'); // 非表示
      }
    
    }, []);

    return (
      <div className={`logo-container ${logoState}`}>
        <p><img className={`logo-img ${logoState}`} src={img} alt="Logo"/></p>
      </div>
    );
}

export default FirstVisitLogo;
