import React, { useRef, useEffect, useState } from 'react';
import '../css/gallary/gallary.css';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Button } from '@mui/material';

const GalleryItem = (props) => {
  const {src, openModal} = props;
  const [isVisible, setIsVisible] = useState(false);
  const itemRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(itemRef.current);
        }
      },
      { threshold: 0.5 } // 画像が50%以上表示された時にトリガーする
    );

    observer.observe(itemRef.current);

    // cleanup関数でObserverの監視を解除
    return () =>{ 
      if (itemRef.current) { // 要素が存在する場合にのみ unobserve を実行する
        observer.unobserve(itemRef.current);
      }
    }
  }, []);
  
  return (
    <div className={`gallery-item`} ref={itemRef} >
      <img src={src} alt="Gallery Item"
        className={isVisible ? 'zoomIn' : ''} 
        onClick={(e)=>openModal(e, src)}/>
    </div>
  );
}


function GalleryDishesPage({ match }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const { dishId } = useParams();
  const navigate = useNavigate();
  useEffect(()=>{
    const fetchImgFile = async ()=>{
      
      const query = new URLSearchParams({dishId: dishId});
      const res = await fetch(`/tree/api/selectGallery?${query}`);
      const data = await res.json();
      console.log(data)
      setImages(data);

    }
    fetchImgFile();
  },[])

  const openModal = (e, image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const handleReturnButton = () => {
    navigate(-1); 
  }


  return (    
    <div>
      <Button
        onClick={handleReturnButton}
      >
        <KeyboardReturnIcon fontSize='large'/>
      </Button>
      <ul class="gallery">
        {images.map((image, index) => (
          <li><GalleryItem src={`/img/dishes/${image.FILENAME}`} openModal={openModal}/></li>
        ))}
      </ul>
      
      {selectedImage && (
        <div className="modal"  onClick={closeModal}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={selectedImage} alt="Selected Image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default GalleryDishesPage;
