import * as commonFnc from '../bl/BL_common';

class treeInformationClass {
   
    constructor(){
        this.INFO_ID=null
        this.TITLE=""
        this.NOTE=""
        this.FROM_DATE=commonFnc.formatDate(new Date())
        this.TO_DATE=commonFnc.formatDate(new Date())
        this.INPOTANT_FLG=false
        this.IS_VIEW_FLG=false
        this.MODAL_VIEW_FLG=false
        this.CREATE_DATE=""
        this.CREATE_USER=""
        this.UPDATE_DATE=""
        this.UPDATE_USER=""
  }

}

export default treeInformationClass;