import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';

import '../css/viewIFrame/viewIFrame.css';

import ViewIFrame from '../conpornent/ViewIFrame';
import SummaryButton from "../conpornent/SummaryButton";

function SummryReserveInfoPage(props) {
  
  const envShinryouUrl = useContext(GlobalContext).envShinryouUrl;
  const {iframeUrl, setIframeUrl} = useContext(GlobalContext);
  
  const [seletedHp, setSelectedHp] = useState('MC');

  useEffect(()=>{
    setIframeUrl(envShinryouUrl.MCURL);
  },[])


  return (
    <div>
      <div class="buttonArea">
        <SummaryButton 
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='三宅医院'
          clinicStr='MC'
          url={envShinryouUrl.MCURL}
        />
        <SummaryButton 
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='おおふく'
          clinicStr='MOC'
          url={envShinryouUrl.MOCURL}
        />
        <SummaryButton 
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='テラス'
          clinicStr='MCTT'
          url={envShinryouUrl.MCTTURL}
        />
        <SummaryButton 
          seletedHp={seletedHp}
          setSelectedHp={setSelectedHp}
          setIframeUrl={setIframeUrl}
          label='ハロー'
          clinicStr='HELLO'
          url={envShinryouUrl.HELLOURL}
        />
      </div>
      <ViewIFrame
        iframeUrl={iframeUrl}
      />
    </div>
  );
}

export default SummryReserveInfoPage;
