import '../../css/top/mainMenuButton.css';

import Appbar from '../../conpornent/Appbar'
import NoticeList from '../../conpornent/NoticeList'

import Box from '@mui/material/Box';

function Tsukicarekids() {
  return (
    <Box>
        <h1 class="pageTitle">夜間往診について</h1>
        <section>
            <div class="contentWrapper">
                <h3 class="sectionTitle">2024年4月　夜間往診が始まりました。</h3>
                <div class="content">
                    <p>当院を受診中のお子さまを対象に、夜間往診を行っております。
                        子どもは体調を崩しやすく、急な発熱や嘔吐下痢など不安や心配が尽きません。
                        小児科の医師がご自宅まで伺い、診察を行います。（玄関先でも診察可）
                        ご希望の方はお気軽にご相談ください。</p>
                </div>
            </div>
        </section>
        <section>
            <div class="contentWrapper">
            <h3 class="sectionTitle">Q&A</h3>
            <NoticeList/>
            </div>
        </section>
    </Box>
  );
}

export default Tsukicarekids;
