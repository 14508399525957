import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';
import treeInformationClass from "../classes/treeInformationClass";
import { Box, Button, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Switch, TextField, Typography } from "@mui/material";
import axios from 'axios';
import { selectTreeInformationList } from "../query/selectTreeInformationList";
import * as commonFnc from '../bl/BL_common';
import { useNavigate } from "react-router-dom";



const style = {
  BoxStyle:{
    Width:'100%',
    padding:'5%',
  },
  TextFieldStyle:{
    width:"100%",
    margin:'0 auto 10px auto',
  },
  ButtonStyle:{
    width:"100%",
    margin:'10px auto',
  },
  FormControl:{
    width:"100%",
    border:"1px solid #d4d4d1"
  },
  ListSubheader:{
    display:'flex',
  },
  ListSubheaderText:{
    width:'85%',
  }
}


/**
 * TREEお知らせ情報一覧画面。
 * @param {*} props
 * @returns 
 */
function TreeInformationListAdminPage(props) {

  const [treeInformationList, setTreeInformationList] = useState([]);
  const {selectedTreeInformationId, setSelectedTreeInformationId} = useContext(GlobalContext);

  const navigate = useNavigate();

  
  useEffect(()=>{
    const fetchTreeInformationList = async ()=>{
      setTreeInformationList(await selectTreeInformationList());
    }
    fetchTreeInformationList();
    
  },[])
  

  const handleInfoClick = (infoId) =>{
    setSelectedTreeInformationId(infoId);
    navigate('/admin/treeInformationForm');
  }

  const handleNewInfo = () => {
    setSelectedTreeInformationId(null);
    navigate('/admin/treeInformationForm');
  }

  return (
      <Box sx={style.BoxStyle}>
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" sx={style.ListSubheader}>
          <Box component="div" sx={style.ListSubheaderText}>お知らせ一覧</Box>
          <Button 
            variant="outlined"
            onClick={()=>handleNewInfo()}
          >新規</Button>
        </ListSubheader>
      }
    >
    {treeInformationList.length === 0?
    <div class="LoaderContener"><div class="loader"></div></div>
    :<>
          {treeInformationList.map((treeInformation, index)=>(
            <>
            <ListItemButton 
            
              className={`${treeInformation.READ_DATE ? "already-read" : false} ${treeInformation.INPOTANT_FLG ? "important-info" : false}`}
              onClick={()=>handleInfoClick(treeInformation.INFO_ID)}
            >
              <ListItemText
                primary={`${commonFnc.formatDate(treeInformation.CREATE_DATE)}　　${treeInformation.TITLE}`} 
                secondary={treeInformation.NOTE}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            </ListItemButton>
            <Divider/>
            </>
          ))}
          </>
        }
        </List>
      </Box>
  );
}

export default TreeInformationListAdminPage;
