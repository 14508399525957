import '../css/viewIFrame/viewIFrame.css';

import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

function CardList(props) {
    const {
        url,
        title,
        subTitle,
        discription,
        img,
    } = props;
    
    const handleListItemClick = (url) => {
        // 同じタブでページ遷移するとLocinUserInfoが消えるため別タブで起動
        // window.open(url, '_blank');
        window.location.href = url;
    }

    return (
        <ListItem alignItems="flex-start">
            {/* <ListItemButton component="a" href={url}> */}
            <ListItemButton onClick={()=>handleListItemClick(url)}>
                <ListItemAvatar>
                    <img alt="施設" src={img} style={{width:"100px", 'marginRight':'10px' }}/>
                </ListItemAvatar>
                <ListItemText
                    primary={title}
                    secondary={
                    <React.Fragment>
                        <Typography
                        sx={{ display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                        >
                        {subTitle}
                        </Typography>
                        {discription}
                    </React.Fragment>
                    }
                />
            </ListItemButton>
        </ListItem>
    );
}

export default CardList;
