// import '../../css/top/mainMenuButton.css';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';
import VideoModalComponent from '../conpornent/VideoModalComponent';

function ManualLsitPage() {

    const [expanded, setExpanded] = useState('panel1');

    // video test
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [videoFile, setVideoFile] = useState('');

    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleVideoStart = (videoFilePath) =>{

        setVideoFile(videoFilePath);
        setVideoModalOpen(true);

    }

    return (
        <Box>
            <h1 class="pageTitle">マニュアル</h1>
            <section>
                <div class="contentWrapper">
                {/* <h3 class="sectionTitle">Q&A</h3> */}
                
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    2024-03-29
                    </Typography> */}
                    <Typography sx={{ color: 'text.primary', fontWeight:'bold'}}>アプリのインストール方法</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        ご使用中の機種ごとにインストール方法が異なります。
                    </Typography>
                    <List>
                        <ListItemButton onClick={()=>handleVideoStart('video/manual_app_install_android.mp4')}>
                            <ListItemText primary="Android ※動画" />
                        </ListItemButton>
                        <ListItemButton onClick={()=>handleVideoStart('video/manual_app_install_iphone.mp4')}>
                            <ListItemText primary="iPhone ※動画" />
                        </ListItemButton>
                    </List>
                </AccordionDetails>
                </Accordion>
                </div>
            </section>
            <VideoModalComponent
                title="アプリのインストール方法"
                videoModalOpen={videoModalOpen}
                setVideoModalOpen={setVideoModalOpen}
                videoFile={videoFile}
            ></VideoModalComponent>
        </Box>
    );
}

export default ManualLsitPage;
