
import * as React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate } from 'react-router-dom';

function MenuItemLink(props) {
  const navigate = useNavigate();

  const {
    icon,
    itemName,
    url
  } = props;

  const handleLinkClick = (url) => {
    // 同じタブでページ遷移するとLocinUserInfoが消えるため別タブで起動
    // window.open(url, '_blank');
    window.location.href = url;
  };

  
  return (
    <ListItem key={itemName} disablePadding>
      <ListItemButton component="a" onClick={()=>handleLinkClick(url)} >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={itemName} />
      </ListItemButton>
    </ListItem>
  );
}

export default MenuItemLink;
