import React from 'react';
import QRCode from 'react-qr-code';
function QRCodeComponent (props) {

  const {data} = props

  return (
    <>
      <QRCode 
        value={data} 
        style={{ maxWidth: "100%", width: "100%" , height: "auto"}}
      />
    </>
  );
};

export default QRCodeComponent;