import { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';
import { GlobalContext } from "../globalState";
import { useNavigate } from 'react-router-dom';
import { selectUserMail } from "../query/selectUserMail";
import * as BL_LoadLocalStrage from '../bl/BL_LoadLocalStrage';


const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  };

const SignUpVerifyPage = (props) =>{
    const { token } = useParams();
    const navigate = useNavigate();

    const setLoginUserInfo = useContext(GlobalContext).setLoginUserInfo;
    const prevTokenRef = useRef(null); // 前回のtoken値を記憶するリファレンス
    const [message, setMessage] = useState('メール認証中です。');

    useEffect(()=>{
        
        if(prevTokenRef.current===token) return;
        prevTokenRef.current = token;

        const verifyToken = async () =>{
            const query = new URLSearchParams({token: token});
            const url = `/tree/api/verify?${query}`;
            const res = await fetch(`${url}`);
            if(res.ok){
                const user = await res.json();

                // ログインユーザ情報をグローバルStateに保存
                const dataloginUserInfoList = await selectUserMail(user[0]?.MAIL);
                BL_LoadLocalStrage.login(dataloginUserInfoList[0]);
                setLoginUserInfo(dataloginUserInfoList[0]);
                navigate('/');

            }else{
                const errorMessage = await res.text();
                alert(errorMessage);
                setMessage(errorMessage);
            }
        }
        verifyToken();
    },[token]);

    return (
        <div style={style}>
            <h3>{message}</h3>
            <h3>{token}</h3>
        </div>
    );
}

export default SignUpVerifyPage;