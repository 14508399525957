import React, { useRef } from 'react';
import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';
import { Box, Modal, Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function VideoModalComponent (props) {

  const {
    title,
    videoModalOpen, 
    setVideoModalOpen,
    videoFile,
  } = props
  
  const {loginUserInfo} = useContext(GlobalContext);  

  const handleOpen = () => setVideoModalOpen(true);
  const handleClose = () => setVideoModalOpen(false);

  const videoRef = useRef(null);

  const handlePlay = () => {
    videoRef.current.play();
  };

  const handlePause = () => {
    videoRef.current.pause();
  };

  return (
      <Modal
        open={videoModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' , marginBottom:'5%'}}>
            {title}
          </Typography>
          <div>
            <video ref={videoRef} width="100%" controls autoPlay muted>
              <source src={videoFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Box>
      </Modal>
  );
};

export default VideoModalComponent;