import './App.css';
import './css/loader/loader.css'
// import './css/appBackground.css';

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GlobalContext } from "./globalState";

import {GlobalProvider} from './globalState';

import Appbar from './conpornent/Appbar';
import Top from './page/Top';
import Miyake_LoadMap from './page/contents/Miyake_LoadMap';
import SummryHPPage from './page/SummryHPPage';
import AtlinkWEBMonshinListPage from './page/AtlinkWEBMonshinListPage';
import DockKenshinReserveListPage from './page/DockKenshinReserveListPage';
import SummryReserveInfoPage from './page/SummryReserveInfoPage';
import ReserveListPage from './page/ReserveListPage';
import SummryInfomationPage from './page/SummryInfomationPage';
import MaintenancePage from './page/MaintenancePage';
import QRCodePage from './page/QRCodePage';
import SignInPage from './page/SignInPage';
import SignUpPage from './page/SignUpPage';
import SignUpVerifyPage from './page/SignUpVerifyPage';
import ViewExternalWebPage from './page/ViewExternalWebPage';
import GlobalStateComponent from './conpornent/GlobalStateComponent';
import BookShelfPage from './page/BookShelfPage';
import GalleryDishesPage from './page/GallaryDishesPage';
import GalleryDishesTopPage from './page/GallaryDishesTopPage';
import Tsukicarekids from './page/contents/Tsukicarekids';
import ManualLsitPage from './page/ManualLsitPage';
import TreeInformationFormAdminPage from './page/TreeInformationFormAdminPage';
import TreeInformationListAdminPage from './page/TreeInformationListAdminPage';

import TreeInformationModalComponent from "./conpornent/TreeInformationModalComponent";
import { selectTreeInformationIsViewList } from "./query/selectTreeInformationIsViewList";
import TreeInformationListPage from './page/TreeInformationListPage';
import TreeInformationFormPage from './page/TreeInformationFormPage';
import PatientCallListPage from './page/PatientCallListPage';
import QRCodeReaderPage from './page/QRCodeReaderPage';
import PatientCallListDisplayPage from './page/PatientCallListDisplayPage';
import BarCodeReaderPage from './page/BarCodeReaderPage';


const mainTheme = createTheme ({
  palette: {
    primary: {
      main: "#d499a6"
    },
    secondary: {
      main: "#ecd082"
    }
  }
});


function App() {
  
  const [logoState, setLogoState] = useState('');

  
  const [treeInformationModalOpen, setTreeInformationModalOpen] = useState(false);
  const [treeInformationList, setTreeInformationList] = useState([]);
  const [treeInformationIsModalList, setTreeInformationIsModalList] = useState([]);
  
  // 初回起動時にロゴを表示する。
  useEffect(() => {
    const welcomeMessage = () => {
      const userInfoString = localStorage.getItem('userInfo');
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
      if(userInfo){
        alert(`${userInfo?.FIRST_NAME}さん。お帰りなさい。`)
      }
    }


    const fetchTreeInformationIsViewList = async ()=>{
      const userInfoString = localStorage.getItem('userInfo');
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

      const infoList = await selectTreeInformationIsViewList(userInfo?.TREEID);
      const isModalViewInfoList = infoList.filter(p=>p.MODAL_VIEW_FLG && p.READ_DATE===null);
      setTreeInformationList(infoList);
      setTreeInformationIsModalList(isModalViewInfoList);
    }
    fetchTreeInformationIsViewList();


    
    const isFirstVisitSession = sessionStorage.getItem('isFirstVisit');
    if (!isFirstVisitSession) {
      sessionStorage.setItem('isFirstVisit', 'false');

      setTimeout(()=>{
        setLogoState('fade-in'); // フェードイン

        setTimeout(()=>{
          setLogoState('fade-out'); // フェードアウト
  
          setTimeout(()=>{
            setLogoState('finished'); // 非表示
            welcomeMessage();
            setTreeInformationModalOpen(true); // モーダルお知らせ表示
          },2000);
          
        },2000);
      },0);


    }else{
      setLogoState('finished'); // 非表示
    }
  
  }, []);


  return (
    <GlobalProvider>
      <ThemeProvider theme={mainTheme}>
        <BrowserRouter>
          <GlobalStateComponent/>
          <Appbar/>
          <div className='app-body'></div>
          <Routes>
            {/* TOPページ */}
              <Route exact path="/" element={<div class="bodyContentsWrap"><Top/></div>}/>
              <Route exact path="/mcLoadMap" element={<div class="bodyContentsWrap"><Miyake_LoadMap/></div>}/>
              <Route exact path="/viewHp" element={<div class="bodyContentsWrap"><SummryHPPage/></div>}/>
              <Route exact path="/viewReserveInfo" element={<div class="bodyContentsWrap"><SummryReserveInfoPage/></div>}/>
              <Route exact path="/viewInfomation" element={<div class="bodyContentsWrap"><SummryInfomationPage/></div>}/>
              <Route exact path="/viewWEBMon" element={<div class="bodyContentsWrap"><AtlinkWEBMonshinListPage/></div>}/>
              <Route exact path="/dockReserve" element={<div class="bodyContentsWrap"><DockKenshinReserveListPage/></div>}/>
              <Route exact path="/galleryDishes/gallery/:dishId" element={<div class="bodyContentsWrap"><GalleryDishesPage/></div>}/>
              <Route exact path="/reserve" element={<div class="bodyContentsWrap"><ReserveListPage/></div>}/>
              <Route exact path="/maintenance" element={<div class="bodyContentsWrap"><MaintenancePage/></div>}/>
              <Route exact path="/qr" element={<div class="bodyContentsWrap"><QRCodePage/></div>}/>
              <Route exact path="/SignIn" element={<div class="bodyContentsWrap"><SignInPage/></div>}/>
              <Route exact path="/SignUp" element={<div class="bodyContentsWrap"><SignUpPage/></div>}/>
              <Route exact path="/Verify/:token" element={<div class="bodyContentsWrap"><SignUpVerifyPage/></div>}/>
              <Route exact path="/viewExternalWeb" element={<div class="bodyContentsWrap"><ViewExternalWebPage/></div>}/>
              <Route exact path="/bookshelf" element={<div class="bodyContentsWrap"><BookShelfPage/></div>}/>
              <Route exact path="/galleryDishes" element={<div class="bodyContentsWrap"><GalleryDishesTopPage/></div>}/>
              <Route exact path="/tsukicare" element={<div class="bodyContentsWrap"><Tsukicarekids/></div>}/>
              <Route exact path="/manuals" element={<div class="bodyContentsWrap"><ManualLsitPage/></div>}/>
              <Route exact path="/treeInformationForm" element={<div class="bodyContentsWrap"><TreeInformationFormPage/></div>}/>
              <Route exact path="/treeInformationList" element={<div class="bodyContentsWrap"><TreeInformationListPage/></div>}/>
              <Route exact path="/paymentsCallList" element={<div class="bodyContentsWrap"><PatientCallListPage/></div>}/>
              <Route exact path="/admin/treeInformationForm" element={<div class="bodyContentsWrap"><TreeInformationFormAdminPage/></div>}/>
              <Route exact path="/admin/treeInformationList" element={<div class="bodyContentsWrap"><TreeInformationListAdminPage/></div>}/>
              <Route exact path="/admin/qrCodeReaderPage" element={<div class="bodyContentsWrap"><QRCodeReaderPage/></div>}/>
              <Route exact path="/admin/barCodeReaderPage" element={<div class="bodyContentsWrap"><BarCodeReaderPage/></div>}/>
              <Route exact path="/display/:dispDeptgrCd/:dispDisplay" element={<div class="bodyContentsWrap"><PatientCallListDisplayPage/></div>}/>
          </Routes>
        </BrowserRouter>
        <div className={`logo-container ${logoState}`}>
          <p><img className={`logo-img ${logoState}`} src="/img/logo.png" alt="Logo"/></p>
        </div>
        
        <TreeInformationModalComponent
            treeInformationModalOpen={treeInformationModalOpen}
            setTreeInformationModalOpen={setTreeInformationModalOpen}
            treeInformationIsModalList={treeInformationIsModalList}
        />
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default App;
