import { useState, useEffect, useRef } from "react";
import { selectCallList } from "../query/selectCallList";
import { List, ListItem, ListItemText, Box, Button } from "@mui/material";
import * as commonFnc from '../bl/BL_common';
import '../css/PatientCallListDisplay/PatientCallListDisplay.css'
import { useParams } from 'react-router-dom';
import axios from "axios";

function PatientCallListDisplayPage(props) {

  const { dispDeptgrCd, dispDisplay } = useParams();

  // const selectedDeptgrCd = "5";
  const [callViewList, setCallViewList] = useState(null);
  const latestId = useRef(null);

  const [blinkActive, setBlinkActive] = useState(false); // 点滅のアクティブ状態

  // 当日の呼出情報を取得する
  const fetchCallViewList = async () => {
    const callList = await selectCallList(dispDeptgrCd, dispDisplay);
    setCallViewList(callList.slice(0, 15)); // 先頭15件を抽出
    if(callList[0]?.PATIENT_ID !== latestId.current){
      setBlinkActive(true);
      
    }
    latestId.current=(callList[0]?.PATIENT_ID);
  };

  useEffect(() => {
    fetchCallViewList();

    // 5秒ごとに再取得
    const intervalId = setInterval(() => { fetchCallViewList() }, 5000);

    // クリーンアップ関数でタイマーをクリア
    return () => clearInterval(intervalId);
  }, []);



  // 5秒後に点滅を停止する
  useEffect(() => {
    if(!blinkActive) return;
    const timeoutId = setTimeout(() => {
      setBlinkActive(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [blinkActive]);


  // ダブルクリックで表示中のIdを削除
  const handleDoubleClick = async (callView) => {
    const result = window.confirm(`${commonFnc.removeLeadingZeros(callView.PATIENT_ID)}を削除しますか？`);
    if(result){
      const callId = callView.CALLID;

      // 呼出情報削除リクエストを送信
      await axios.post('/tree/api/deleteCallPatientId', { callId });
      fetchCallViewList();
    }
  }

  return (
    <div>
      <h2 className="patientCallListTitle">会計呼出済み(最新15件)<br/>自動精算機でお支払いください。</h2>
      <>
        {callViewList === null ?
          <div className="LoaderContener"><div className="loader"></div></div>
          :
          <Box className="patientCallListContainer">
            {callViewList.map((callView, index) => (
              <Button onDoubleClick={()=>handleDoubleClick(callView)}>
                <Box className={`patientCallListItem ${index === 0 && blinkActive ? 'blink' : ''}`} key={index}>
                    {commonFnc.removeLeadingZeros(callView.PATIENT_ID)}
                </Box>
              </Button>
            ))}
          </Box>
        }
      </>
    </div>
  );
}

export default PatientCallListDisplayPage;

