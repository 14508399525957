import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';


import ViewIFrame from '../conpornent/ViewIFrame';

/**
 * 外部サイトを表示する
 * アットリンク用に作成したが、iFrameの読み込みを禁止しているため
 * このコンポーネントは使用することが出来なかった。。
 * @param {*} props 
 * @returns 
 */
function ViewExternalWebPage(props) {
  const {iframeUrl, setIframeUrl} = useContext(GlobalContext);
  
  return (
      <ViewIFrame
        iframeUrl={iframeUrl}
      />
  );
}

export default ViewExternalWebPage;
