import React, { useRef, useEffect, useState } from 'react';
import DishesCard from '../conpornent/DishesCard';
import FirstVisitLogo from '../conpornent/FirstVisitLogo';
import MenuItemLink from '../conpornent/MenuItemLink';
import InstagramIcon from '@mui/icons-material/Instagram';

import '../css/gallary/gallary.css'

function GalleryDishesTopPage() {

  const [dishes, setDishes] = useState([]);

  useEffect(()=>{
    const fetchData = async () =>{
      const res = await fetch('tree/api/selectDishes');
      const data = await res.json();
      setDishes(data);
    }
    fetchData();
  },[])


  return (    
    <div>
    <FirstVisitLogo
      sessionTagName='isDeshesPageFirstVisit'
      img='img/syokuzai.png'
    />
    <MenuItemLink
      icon={<InstagramIcon/>}
      itemName={'Dishes Instagram'}
      url={'instagram://user?username=miyakeclinicgroup'}
    />
      {
        dishes.map((dish)=>(
          <DishesCard
            title={dish.TITLE}
            subTitle={dish.SUBTITLE}
            discription={dish.DISCRIPTION}
            img={dish.MAIN_IMG_PATH}
            dishId={dish.DISHID}
          />
        ))
      }
    </div>
  );
}

export default GalleryDishesTopPage;
